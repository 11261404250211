import React, { useEffect, useState } from 'react';

interface IDeviceContext {
  deviceType: 'mobile' | 'desktop';
  width: number;
  height: number;
}

interface IDeviceProviderProps {
  defaultDeviceType?: IDeviceContext['deviceType'];
  children: React.ReactNode;
}

const DeviceContext = React.createContext<IDeviceContext>(null);

export const DeviceProvider: React.FC<IDeviceProviderProps> = ({ defaultDeviceType, children }) => {
  const [windowSize, setWindowSize] = useState<Omit<IDeviceContext, 'deviceType'>>();

  const [deviceType, setDeviceType] = useState<IDeviceContext['deviceType']>(defaultDeviceType);

  useEffect(() => {
    const handleSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

      setDeviceType(window.innerWidth <= 768 ? 'mobile' : 'desktop');
    };

    handleSize();

    window.addEventListener('resize', handleSize);
    return () => {
      window.removeEventListener('resize', handleSize);
    };
  }, []);

  const value = React.useMemo(
    () => ({
      deviceType,
      width: windowSize?.width,
      height: windowSize?.height,
    }),
    [deviceType, windowSize]
  );

  return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>;
};

export const useDevice = () => {
  const context = React.useContext(DeviceContext);

  if (context === undefined) {
    throw new Error('useDevice must be used within a DeviceProvider');
  }

  return context;
};
