import ApiClient from '@/configs/ApiClient';
import { IChannelID, IGroupPath, IMsgType } from '../interfaces';

const api = new ApiClient(`${process.env.NEXT_PUBLIC_GAM_CHAT_BE}/api/`).getInstance();

export const ROUTES = {
  GET_CHANNELS: 'v1/channels',
  MESSAGE: 'v1/messages',
  UPLOAD: 'v1/public/upload',
  GET_MENUS: 'v1/menus',
  GET_USER: 'v1/auth/access_token',
  GET_MEDIA: 'v1/media',
  GET_MEDIA_COUNT: 'v1/media/counts',
  GET_PREVIEW_LINK: 'v1/preview_link',
  UPDATE_CHANNEL_OPTIONS: 'v1/channel/members',
  BLOCK_CHANNEL: 'v1/blocks',
};

export interface IGetChannelsParams {
  is_favorite: number;
  group_path?: IGroupPath;
  limit?: number;
  before?: number;
  channel_id?: string;
}

export const getChannels = async <T>(params: IGetChannelsParams) =>
  api.get<T>(ROUTES.GET_CHANNELS, { params });

interface IGetMenusParams {
  type: 'chat';
}

export const getMenus = async (params: IGetMenusParams) => api.get(ROUTES.GET_MENUS, { params });

export interface IGetListMessageParams {
  channel_id: string;
  middle?: string;
  after?: string;
  before?: string;
  limit?: number;
}

export const getListMessages = async <T>(params: IGetListMessageParams) =>
  api.get<T>(ROUTES.MESSAGE, { params });

export interface ISendMessageParams {
  channel_id: string;
  msg_type: IMsgType;
  text: string;
  quote_message_id?: string;
  file_id?: string;
  ref_id?: string;
}

export const sendMessage = async (params: ISendMessageParams) => api.post(ROUTES.MESSAGE, params);

export const uploadFile = async (params: FormData) => {
  return api.post(ROUTES.UPLOAD, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getUserInfo = async () => api.get(ROUTES.GET_USER);

interface IGetChannelsInfoParams {
  channel_id: string;
  group_path: IGroupPath;
  is_favorite: number;
}

export const getChannelsInfo = async (params: IGetChannelsInfoParams) =>
  api.get(ROUTES.GET_CHANNELS, { params });

export enum ChatFileTypes {
  MEDIA = 'media',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  LINK = 'link',
  FILE = 'file',
}

interface IGetMediaParams {
  channel_id: IChannelID;
  type?: 'image,video,audio' | ChatFileTypes;
  limit?: number;
  before?: number;
}

export const getMedia = async <T>(params: IGetMediaParams) => api.get<T>(ROUTES.GET_MEDIA, { params });

export const getMediaCount = async (params: IGetMediaParams) => api.get(ROUTES.GET_MEDIA_COUNT, { params });

interface IGetPreviewLinkParams {
  url: string[];
}

export const getPreviewLink = async (params: IGetPreviewLinkParams) =>
  api.post(ROUTES.GET_PREVIEW_LINK, params);

interface IUpdateChannelOptionsParams {
  channel_id: IChannelID;
  is_notification?: number;
  is_favorite?: number;
  save_media?: number;
}

export const updateChannelOptions = async (params: IUpdateChannelOptionsParams) =>
  api.put(ROUTES.UPDATE_CHANNEL_OPTIONS, params);

interface IBlockChannelParams {
  friend_id: string;
}

export const blockChannel = async (params: IBlockChannelParams) => api.put(ROUTES.BLOCK_CHANNEL, params);

export const unBlockChannel = async (params: IBlockChannelParams) =>
  api.delete(ROUTES.BLOCK_CHANNEL, { params });
