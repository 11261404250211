import { CACHE_MESSAGES_MS } from '../constants';
import {
  IChannelID,
  IChannelMessage,
  IChannelMessages,
  IChannels,
  IMisc,
  ISetChannel,
  ISetChannelMessages,
} from '../interfaces';
import { trueTypeOf } from '../utils';
import { Logger } from './Logger';

export enum IChannelMessageMode {
  BEFORE = 'before',
  AFTER = 'after',
  MAX_BEFORE = 'max_before',
  MAX_AFTER = 'max_after',
}

export class InternalStorage {
  private logger: Logger;
  private timeoutIDs: Map<IChannelID, NodeJS.Timeout>;

  private channelMessages: IChannelMessages;
  private currentSubscribedChannelID: IChannelID[];
  private channelMessageMode: Map<IChannelID, IChannelMessageMode[]>;
  private channels: IChannels;
  private misc: IMisc;

  constructor(_logger: Logger) {
    this.logger = _logger;
    this.timeoutIDs = new Map();

    this.currentSubscribedChannelID = [];
    this.channelMessages = new Map();
    this.channelMessageMode = new Map();
    this.channels = [];
    this.misc = {
      token: '',
      user: null,
      groupPath: null,
    };
  }

  public setCurrentSubscribedChannelID = (channelIDs: any) => {
    if (trueTypeOf(channelIDs) === 'function') {
      this.currentSubscribedChannelID = (channelIDs as (prev: IChannelID[]) => IChannelID[])(
        this.currentSubscribedChannelID
      );
      return;
    }

    this.currentSubscribedChannelID = channelIDs;
  };

  public setChannelMessageMode = (
    channelID: IChannelID,
    mode: IChannelMessageMode[] | ((prev: IChannelMessageMode[]) => IChannelMessageMode[])
  ) => {
    if (trueTypeOf(mode) === 'function') {
      const prevModes = this.channelMessageMode.get(channelID) || [];
      const newModes = (mode as (prev: IChannelMessageMode[]) => IChannelMessageMode[])(prevModes);
      this.channelMessageMode.set(channelID, newModes);

      return;
    }

    this.channelMessageMode.set(channelID, mode as IChannelMessageMode[]);
  };

  public setChannelMessages: ISetChannelMessages = (channelID, messages) => {
    if (trueTypeOf(messages) === 'function') {
      const prevMessages = this.channelMessages.get(channelID) || [];
      const newMessages = (messages as (prev: IChannelMessage[]) => IChannelMessage[])(prevMessages);
      this.channelMessages.set(channelID, newMessages);
      return;
    }

    this.channelMessages.set(channelID, messages as IChannelMessage[]);
  };

  public setChannels: ISetChannel = (params: any) => {
    if (trueTypeOf(params) === 'function') {
      this.channels = (params as (prev: IChannels) => IChannels)(this.channels);
      return;
    }

    const uniqueNewChannels = (params || [])?.filter(
      (newChannel) => !this.channels.some((channel) => channel.channel_id === newChannel.channel_id)
    );

    const newChannels = [...this.channels, ...uniqueNewChannels];

    this.channels = newChannels as IChannels;
  };

  public setMisc = (fn: (misc: IMisc) => IMisc) => {
    this.misc = fn(this.misc);
  };

  public removeMessagesCache = (channelID: string) => {
    const id = setTimeout(() => {
      this.channelMessages.delete(channelID);

      this.setChannels((prev) => {
        return prev.map((channel) => {
          if (channel.channel_id === channelID) {
            channel.has_subscribed = false;
            this.logger.info(`Clear cache messages of channel id: ${channelID}`);
          }

          return channel;
        });
      });
    }, CACHE_MESSAGES_MS);

    this.timeoutIDs.set(channelID, id);
  };

  public removeTimeoutIfExist = (channelID: string) => {
    if (this.timeoutIDs.has(channelID)) {
      clearTimeout(this.timeoutIDs.get(channelID));
      this.timeoutIDs.delete(channelID);
    }
  };

  get data() {
    return {
      currentSubscribedChannelID: this.currentSubscribedChannelID,
      channelMessageMode: this.channelMessageMode,
      channelMessages: this.channelMessages,
      channels: this.channels,
      misc: this.misc,
    };
  }
}
