import { IChannelMessage } from '@/configs/Chat/interfaces';
import cn from 'classnames';
import { SmallLoader } from '@/components/common/SmallLoader';
import { ThreeDots } from '@/components/icons';
import { Popover } from '@/UI';
import { useRef, useState } from 'react';
import { useChat } from '../..';
import { MessageContent } from './MessageContent';
import { MessageQuote } from './MessageQuote';

interface IProps {
  message: IChannelMessage;
  index: number;
  shouldShowTime: boolean;
  hasNextMessageFromSameUser: boolean;
  onClickImg: (id: string) => void;
}

export const Message: React.FC<IProps> = ({
  message,
  shouldShowTime,
  hasNextMessageFromSameUser,
  index,
  onClickImg,
}) => {
  const isCurrentUser = message?.is_current_user;

  return (
    <>
      {shouldShowTime && (
        <div className="created_at text-[12px] text-th-text-hint text-center my-2 select-none">
          {message?.created_at_text}
        </div>
      )}
      <div
        data-message-key={message?.id}
        data-index={index}
        className={cn('chat-wrapper relative flex flex-col w-full mb-2 ', {
          'items-start': !isCurrentUser,
          'items-end': isCurrentUser,
        })}
      >
        <MessageSender
          message={message}
          isCurrentUser={isCurrentUser}
          shouldShowTime={shouldShowTime}
          hasNextMessageFromSameUser={hasNextMessageFromSameUser}
        />
        <div
          className={cn('relative max-w-[60%] flex flex-col', {
            'items-end': isCurrentUser,
            'items-start': !isCurrentUser,
          })}
        >
          <MessageQuote message={message} type="text" />
          <MessageQuote message={message} type="attachment" />

          <div className="message-content relative z-40 border-2 border-solid border-transparent rounded-lg  outline-th-red-500">
            <MessageContent onClickImg={onClickImg} message={message} isCurrentUser={isCurrentUser} />
            <MessageOptions message={message} />
          </div>

          {/* No score means the message is sending */}
          {!message?.score && <SmallLoader className="absolute top-1/2 -left-5 -translate-y-1/2" />}
        </div>
      </div>
    </>
  );
};

const MessageSender = ({ message, isCurrentUser, shouldShowTime, hasNextMessageFromSameUser }) => {
  if (!isCurrentUser && (shouldShowTime || !hasNextMessageFromSameUser))
    return (
      <div className="username text-[11px] text-th-gray-500 text-left select-none mb-1">
        {message?.sender?.fullname}
      </div>
    );
};

const MessageOptions = ({ message }) => {
  const { setQuoteMessage } = useChat();
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const isCurrentUser = message?.is_current_user;

  return (
    <Popover
      open={open}
      rootClassName="p-0"
      trigger={['click']}
      onOpenChange={handleOpenChange}
      content={
        <div className="flex flex-col gap-2">
          <button
            className="hover:bg-th-gray-50 rounded-md transition p-1 text-left"
            onClick={() => {
              setQuoteMessage(message);
              (document.querySelector('#chat-input') as any)?.focus();
              hide();
            }}
          >
            Trích dẫn
          </button>
        </div>
      }
    >
      <button
        ref={ref}
        className={cn(
          `chat-content absolute top-1/2 z-30 -translate-y-1/2 w-[24px] h-[24px] border border-solid border-th-gray-100
           rounded-full bg-th-gray-50 items-center justify-center cursor-pointer`,
          {
            '-right-8': !isCurrentUser,
            '-left-8': isCurrentUser,
          }
        )}
      >
        <ThreeDots className="transform rotate-90 " />
      </button>
    </Popover>
  );
};
