import { ArrowRightIcon, SearchIcon } from '@/components/icons';
import { chatInstance } from '@/configs/Chat';
import { getMenus, ROUTES } from '@/configs/Chat/services/gamp-chat.service';
import { useQuery } from '@tanstack/react-query';
import cx from 'classnames';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ChannelViewLayout from './ChannelViewLayout';
import { useAuth } from '@/configs/Auth/client';
import { IChannelID, IChannelMessage, IGroupPath, IChannelData } from '@/configs/Chat/interfaces';
import cn from 'classnames';
import { ChatChannel } from './components/ChannelItem';
import Widgets from '@/modules/chat/components/widgets';
import { WIDGETS_MAX_SHOW } from '@/modules/chat/constants';

interface IChatContext {
  groupPath?: IGroupPath;
  channelID?: IChannelID;
  quoteMessage?: IChannelMessage;
  widgets?: IChannelData[];
  setWidgets?: (widgets: IChannelData[]) => void;
  setQuoteMessage?: (message: IChannelMessage) => void;
  setCurrentChannelID?: (channelID: IChannelID) => void;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  addChannel?: (channel: IChannelData) => void;
  minimizeChannel?: (channelID: string) => void;
  minimizeAll?: () => void;
}

const ChatContext = createContext<IChatContext>({});

const ChatModule = () => {
  const { userInfo } = useAuth();
  const [quoteMessage, setQuoteMessage] = useState<IChannelMessage>();
  const [currentChannelID, setCurrentChannelID] = useState<IChannelID>();
  const [widgets, setWidgets] = useState<IChannelData[]>([]);
  const [open, setOpen] = useState(true);

  const { data: menus } = useQuery({
    queryKey: [ROUTES.GET_MENUS],
    queryFn: async () => {
      const res = await getMenus({
        type: 'chat',
      });

      return res.data;
    },

    refetchOnWindowFocus: false,
    enabled: !!userInfo,
  });

  useEffect(() => {
    if (!userInfo) return;
    chatInstance.initializeWs(userInfo);
  }, [userInfo]);

  useEffect(() => {
    if (!menus) return;
    const groupPath = menus?.[0]?.group_path;
    if (groupPath) chatInstance.handleSetGroupPath(groupPath);
  }, [menus]);

  const addChannel = useCallback(
    (channel: IChannelData) => {
      // If the widget is already open, skip
      if (widgets.some((item) => item.channel_id === channel.channel_id && !item.is_minimized)) return;

      const newWidgets = widgets.filter((item) => item.channel_id !== channel.channel_id);

      // Minimize the third open widget
      let count = 0;
      const updatedWidgets = newWidgets.map((item) => {
        if (!item.is_minimized) {
          count++;
          if (count === WIDGETS_MAX_SHOW) {
            return { ...item, is_minimized: true };
          }
        }
        return item;
      });

      setWidgets([{ ...channel, is_minimized: false }, ...updatedWidgets]);
    },
    [widgets]
  );

  const minimizeChannel = useCallback((channelID: string) => {
    setWidgets((prev) =>
      prev.map((channel) => (channel.channel_id === channelID ? { ...channel, is_minimized: true } : channel))
    );
  }, []);

  const minimizeAll = useCallback(() => {
    setWidgets((prev) => prev.map((channel) => ({ ...channel, is_minimized: true })));
  }, []);

  const contextValue = useMemo(
    () => ({
      groupPath: menus?.[0]?.group_path,
      channelID: currentChannelID,
      setCurrentChannelID,
      quoteMessage,
      setQuoteMessage,
      open,
      setOpen,
      widgets,
      setWidgets,
      addChannel,
      minimizeChannel,
      minimizeAll,
    }),
    [menus, currentChannelID, quoteMessage, open, widgets, addChannel, minimizeChannel, minimizeAll]
  );

  const toggleChat = () => {
    if (open) {
      setCurrentChannelID(null);
    }

    setOpen(!open);
  };

  return (
    <ChatContext.Provider value={contextValue}>
      <div
        className={cx('w-[350px] flex h-full transition-all', {
          'w-full': currentChannelID,
          '!w-[80px]': !open,
        })}
      >
        <div
          className={cx(
            'relative flex flex-col w-[350px] shrink-0 h-full transition-all bg-th-background border-0 border-r border-solid border-th-border',
            {
              'w-[80px]': !open,
            }
          )}
        >
          <button
            onClick={toggleChat}
            className="absolute bg-th-gray-50 p-[2px] rounded-md top-4 -right-3 border border-solid border-th-border flex items-center justify-center z-50"
          >
            <ArrowRightIcon
              className={cn('rotate-180', {
                'rotate-0': !open,
              })}
            />
          </button>

          {open && (
            <div className="px-4 border-0 border-b border-solid border-th-border relative flex items-center justify-between gap-2 h-14">
              <div className="whitespace-nowrap">Danh sách chat</div>
              <SearchIcon className="cursor-pointer mr-2" />
            </div>
          )}

          <div className="flex-1 overflow-y-auto">
            <ChatChannel />
          </div>
        </div>
        {currentChannelID && (
          <div className="w-full">
            <ChannelViewLayout />
          </div>
        )}
      </div>
      <Widgets />
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }

  return context;
};

export default ChatModule;
