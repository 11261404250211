import React from 'react';
import DOMPurify from 'dompurify';

export const TextContent = ({ text }) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(
          text
            ?.replace(/\n/g, '<br />')
            .replace(
              /(https?:\/\/[^\s]+)/g,
              '<a href="$1" class="underline text-inherit" target="_blank">$1</a>'
            )
        ),
      }}
    />
  );
};
