import { useChannel } from '@/configs/Chat';
import { useChat } from '..';

const ChannelHeader = () => {
  const { channelID } = useChat();
  const { detail } = useChannel(channelID);

  return (
    <div className="flex gap-2 ml-4">
      <div>
        <p className="font-bold mb-0">{detail?.channel_name}</p>
        <p className="text-sm text-th-gray-400 mb-0">Đang hoạt động</p>
      </div>
    </div>
  );
};

export default ChannelHeader;
