import { CloseIcon } from '@/components/icons';

interface IProps {
  label: string;
  onCancel: () => void;
}

export default function Title(props: IProps) {
  return (
    <div className="bg-th-primary flex items-center text-medium">
      <CloseIcon id="close-icon" className="absolute right-4 hover:cursor-pointer" onClick={props.onCancel} />
      <p className="w-full text-center font-medium mb-0">{props.label}</p>
    </div>
  );
}
