import React from 'react';
import { CheckRound, CloseIconBig, Subtract } from '@/components/icons';
import { useChannel } from '@/configs/Chat';
import { useChat } from '@/modules/chat';
import ChannelAvatar from '@/modules/chat/components/ChannelAvatar';

export const WidgetHeader = ({ channelID }) => {
  const { detail } = useChannel(channelID);
  const { widgets, setWidgets, minimizeChannel } = useChat();
  const avatarUrl = detail?.avatar ?? detail?.group_images;

  const onClose = () => {
    setWidgets(widgets.filter((item) => item.channel_id !== channelID));
  };

  return (
    <div className="px-3 flex justify-between gap-3 h-[64px] items-center shrink-0">
      <div className="flex gap-2 items-center shrink-0">
        <div className="relative w-[48px] h-[48px] shrink-0 ">
          <ChannelAvatar src={avatarUrl} name={detail?.channel_name} />
        </div>
        <div className="flex flex-col">
          <p className="m-0 font-bold flex items-center">
            {detail?.channel_name || `${detail?.channel_type.toLocaleUpperCase()} ${detail?.channel_id}`}
            <CheckRound className="ml-2 bg-th-primary rounded-full " />
          </p>
          <p className="text-xs text-th-gray-400 m-0">Đang online</p>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <div
          className="w-4 h-4 cursor-pointer flex items-center justify-center"
          onClick={() => minimizeChannel(channelID)}
        >
          <Subtract />
        </div>
        <div className="w-6 h-6 cursor-pointer flex items-center justify-center" onClick={onClose}>
          <CloseIconBig />
        </div>
      </div>
    </div>
  );
};
