import { AddPhotoAlternate, PaperClip } from '@/components/icons';
import { Tooltip } from '@/UI';
import { ACCEPT_FILE_TYPE, ACCEPT_MEDIA_TYPE } from '@/modules/chat/constants';

export const Attachments = ({ onUpload }) => {
  return (
    <div className="attachments flex gap-2 items-center mb-1">
      <ButtonFile
        id="files"
        title="Chọn file"
        icon={<PaperClip />}
        accept={ACCEPT_FILE_TYPE}
        onChange={onUpload}
      />

      <ButtonFile
        id="medias"
        title="Chọn media"
        icon={<AddPhotoAlternate />}
        accept={ACCEPT_MEDIA_TYPE}
        onChange={onUpload}
      />
    </div>
  );
};

const ButtonFile = ({ id, title, icon, accept, onChange }) => {
  return (
    <>
      <label htmlFor={id} className="cursor-pointer h-[28px]">
        <Tooltip title={title}>{icon}</Tooltip>
      </label>
      <input id={id} type="file" hidden accept={accept} onChange={onChange} multiple />
    </>
  );
};
