import { Skeleton } from '@/UI';

const SkeletonLoading = () => {
  return (
    <div className="px-2 pt-1 overflow-hidden h-full">
      {Array.from({ length: 5 }).map((_, index) => (
        <Skeleton active key={index} />
      ))}
    </div>
  );
};

export default SkeletonLoading;
