import { Button, Form, Input, Modal } from '@/UI';
import Title from '@/components/common/modal/Title';
import { createShop } from '@/services/Customer.service';
import { APIResponse } from '@/interfaces';
import { showMessage } from '@/components/messages/GMessage';
import { GAM_WEBSITE_SOURCE } from '@/constants';

const Label = ({ text }) => <span className="text-th-text-secondary font-medium leading-5">{text}</span>;

const CreateShop = ({
  open,
  onCancel,
  refetch,
}: {
  open: boolean;
  onCancel: () => void;
  refetch: () => void;
}) => {
  const handleFinish = async (values) => {
    const { error }: APIResponse = await createShop({ ...values, source: GAM_WEBSITE_SOURCE });

    if (error) return showMessage.error(error);
    showMessage.success('Thêm shop thành công');
    onCancel();
    refetch();
  };

  return (
    <Modal
      width={600}
      title={<Title onCancel={onCancel} label="Thêm shop" />}
      footer={null}
      open={open}
      onCancel={onCancel}
      destroyOnClose
      centered
      closable={false}
    >
      <Form
        className="flex flex-col gap-4 p-4 max-h-[60vh] overflow-auto"
        layout="vertical"
        colon={false}
        onFinish={handleFinish}
      >
        <Form.Item
          className="mb-0 w-full"
          label={<Label text="Họ và tên" />}
          rules={[{ required: true, message: 'Vui lòng nhập họ và tên' }]}
          name="full_name"
        >
          <Input placeholder="Nhập họ và tên" />
        </Form.Item>

        <Form.Item
          className="mb-0 w-full"
          label={<Label text="Số điện thoại" />}
          rules={[{ required: true, message: 'Vui lòng nhập số điện thoại' }]}
          name="phone_number"
        >
          <Input placeholder="Nhập số điện thoại" />
        </Form.Item>

        <Form.Item
          className="mb-0 w-full"
          label={<Label text="Email" />}
          rules={[{ required: true, message: 'Vui lòng nhập email' }]}
          name="email"
        >
          <Input placeholder="Nhập email" />
        </Form.Item>

        <Form.Item
          className="mb-0 w-full"
          label={<Label text="Tên công ty" />}
          rules={[{ required: true, message: 'Vui lòng nhập tên công ty' }]}
          name="name"
        >
          <Input placeholder="Nhập tên công ty" />
        </Form.Item>

        <Form.Item className="mb-0 w-full" label={<Label text="Mã số thuế" />} name="tax_code">
          <Input placeholder="Nhập mã số thuế" />
        </Form.Item>
        <Button htmlType="submit" className="w-[60%] mx-auto" type="primary">
          Áp dụng
        </Button>
      </Form>
    </Modal>
  );
};

export default CreateShop;
