import { showMessage } from '@/components/messages/GMessage';
import { useState } from 'react';
import { AttachmentUpload } from './AttachmentUpload';
import { Attachments } from './Attachments';
import { MessageInput } from './MessageInput';
import { MAXIMUM_FILE_SIZE } from '@/modules/chat/constants';
import { AddWrapper, SendMessageIcon } from '@/components/icons';
import { chatInstance } from '@/configs/Chat';
import { scrollToBottom } from '@/configs/Chat/utils';

export const WidgetFooter = ({ channelID }) => {
  const [attachments, setAttachments] = useState<File[]>([]);
  const [inputValue, setInputValue] = useState('');

  const handleAddAttachment = (newAttachments: File[]) => {
    setAttachments([...attachments, ...newAttachments]);
  };

  const handleUpload = async (event) => {
    const files = event.target.files;

    if (Array.from(files)?.some((file: any) => file?.size > MAXIMUM_FILE_SIZE)) {
      showMessage.error('Vui lòng chọn file có dung lượng nhỏ hơn 20MB');
      const fileConsist = Array.from(files).filter((file: any) => file?.size <= MAXIMUM_FILE_SIZE) as File[];
      handleAddAttachment(fileConsist);
      return;
    }

    handleAddAttachment(files);
  };

  const handleRemoveAttachment = (index) => {
    const newAttachments = attachments.filter((_, idx) => idx !== index);
    setAttachments(newAttachments);
  };

  const handleSendMsgs = async () => {
    const files = attachments;
    const message = inputValue.trim();
    if (!message && files.length === 0) return;
    setAttachments([]);
    setInputValue('');

    await chatInstance.handleSendMessage({
      message,
      files,
      channelID,
      onShowTempMessage: () => scrollToBottom(channelID),
    });
  };

  const handlePasteImg = (event) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const blob = items[i].getAsFile();
        handleAddAttachment([blob]);
      }
    }
  };

  return (
    <div className="chat-footer flex items-end justify-between gap-2 p-4 bg-white">
      <div className="footer-input flex-1 bg-th-background-2 rounded-3xl flex flex-col">
        {attachments.length > 0 && (
          <div className="preview-attachments flex gap-1 mx-3 py-3 w-[300px]">
            <div className="flex gap-1 max-w-[220px] overflow-x-auto">
              {Array.from(attachments).map((attachment, index) => (
                <AttachmentUpload
                  key={index}
                  index={index}
                  attachment={attachment}
                  onRemoveAttachment={handleRemoveAttachment}
                />
              ))}
            </div>
            <AddMoreAttachment onUpload={handleUpload} />
          </div>
        )}
        <MessageInput
          handlePasteImg={handlePasteImg}
          onSendMessage={handleSendMsgs}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </div>
      {inputValue || attachments.length > 0 ? (
        <SendMessageIcon className="mb-1 cursor-pointer text-th-primary" onClick={handleSendMsgs} />
      ) : (
        <Attachments onUpload={handleUpload} />
      )}
    </div>
  );
};

const AddMoreAttachment = ({ onUpload }) => {
  return (
    <>
      <label
        htmlFor="upload"
        className="bg-th-gray-150 rounded-lg flex justify-center items-center cursor-pointer sticky right-0 w-[80px] h-[80px] p-2"
      >
        <AddWrapper />
      </label>
      <input id="upload" type="file" hidden accept="*" onChange={onUpload} multiple />
    </>
  );
};
