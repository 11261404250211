import Author from '@/components/auth/Author';
import { useRef } from 'react';
import AppHeader from '../components/AppHeader';
import ScrollTopButton from '../components/ScrollToTop';
import ChatLayout from '../chat';

interface IProps {
  children: React.ReactNode;
}

export default function DefaultLayout({ children }: IProps) {
  const scrollContainer = useRef<HTMLDivElement | null>(null);

  const handleGotoTop = () => {
    scrollContainer.current?.scrollTo({
      behavior: 'smooth',
      top: 0,
      left: 0,
    });
  };

  return (
    <div className="flex flex-col flex-1 w-full overflow-hidden h-screen bg-th-background text-th-text">
      <AppHeader />
      <div className="flex flex-1 overflow-hidden">
        <ChatLayout />
        <div
          ref={scrollContainer}
          className="overflow-x-hidden overflow-y-auto flex flex-col flex-1 relative bg-th-background"
        >
          <Author renderContent={children} />
        </div>
      </div>
      <div className="block relative w-full h-0">
        <ScrollTopButton ref={scrollContainer} onGotoTop={handleGotoTop} />
      </div>
    </div>
  );
}
