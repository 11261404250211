import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { ErrorFallback } from './components/ErrorFallback';
import { Spin } from '@/UI';
import { IUserInfo } from '../interfaces';
import { APIResponse } from '@/interfaces';
import { ERROR_ROUTER, PUBLIC_ROUTER } from '../constants';
import { getSession, getUserInfo } from '@/services/Auth.service';

interface Props {
  children: React.ReactNode;
}

interface IAuthContext {
  handleLogOut: (redirect?: string) => void;
  userInfo: IUserInfo;
}

type IAuthStatus = 'loading' | 'success' | 'blocked' | 'error';

const AuthContext = createContext<IAuthContext | null>(null);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const router = useRouter();
  const [authStatus, setAuthStatus] = useState<IAuthStatus>('loading');
  const { error, error_description } = router.query;
  const pathname: string = router.pathname;
  const [userInfo, setUserInfo] = useState<IUserInfo | null>();

  useEffect(() => {
    if (ERROR_ROUTER.includes(pathname)) {
      localStorage.setItem('token', '1');
      localStorage.removeItem('token');
      return;
    }

    const handleGetSession = async () => {
      const res: APIResponse = await getSession();

      if (res.error && !PUBLIC_ROUTER.includes(pathname)) {
        localStorage.removeItem('access_token');
        window.location.href = '/login';
      }

      const { access_token } = res?.data || {};

      if (access_token) {
        localStorage.setItem('token', access_token);
        const userInfoRes: APIResponse = await getUserInfo();
        if (userInfoRes.error) {
          window.location.href = `/callback?error=${userInfoRes.error}`;
          return;
        }

        setUserInfo(userInfoRes.data);
        setAuthStatus('success');
        if (PUBLIC_ROUTER.includes(pathname)) router.push('/');
        return;
      }
    };

    if (pathname !== '/signup' && PUBLIC_ROUTER.includes(pathname) && authStatus === 'success') {
      router.push('/login');
      return;
    }

    if (authStatus !== 'success') handleGetSession();
  }, [pathname]);

  const handleLogOut = (redirect = '/login') => {
    if (typeof window === 'undefined') return;
    setAuthStatus('loading');
    localStorage.removeItem('token');
    window.location.href = `${process.env.NEXT_PUBLIC_APP_URL}/api/auth/logout?redirect=${redirect}`;
  };

  const authContextValue = useMemo(() => ({ handleLogOut, userInfo }), [userInfo]);

  if (
    !PUBLIC_ROUTER.includes(pathname) &&
    !ERROR_ROUTER?.includes(pathname) &&
    (authStatus === 'loading' || !userInfo)
  )
    return (
      <div className="flex h-screen items-center justify-center">
        <Spin />
      </div>
    );

  if (error)
    return <ErrorFallback error={error} error_description={error_description} handleLogOut={handleLogOut} />;

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
