/* eslint-disable max-lines */
/* eslint-disable no-console */
import { convertMessages, uid } from './utils';
import { MESSAGES_LIMIT, WsListenerType } from './constants';
import { useBaseChannelMessage, useBaseChannel, useBaseChannels, useBaseIsWsConnected } from './hooks';
import {
  IChannelData,
  IChannelID,
  IChannelMessage,
  IChannels,
  IGetChannelsInfoParams,
  IGroupPath,
  IHandleGetListMessageParams,
  IHandleSend,
  IHandleSendMessages,
  IMsgType,
  IUser,
} from './interfaces';
import {
  getChannels,
  getChannelsInfo,
  getListMessages,
  IGetChannelsParams,
  sendMessage,
  uploadFile,
} from './services/gamp-chat.service';
import { showMessage } from '@/components/messages/GMessage';
import { Logger } from './modules/Logger';
import { Emitter, ISubcribeParams } from './modules/Emitter';
import { IChannelMessageMode, InternalStorage } from './modules/InternalStorage';
import { WSClient } from './modules/WSClient';
import { useBaseChannelMsgMode } from './hooks/useChannelMsgMode';

enum IStatus {
  LOADING = 'loading',
  SUCCESS = 'success',
  IDLE = 'idle',
}

class ChatModule {
  private client: WSClient;
  private logger: Logger;
  private emitter: Emitter;
  private internalStorage: InternalStorage;
  private channelStatus: IStatus;
  private messageStatus: Map<IChannelID, IStatus>;

  constructor() {
    // Does not run in server side
    if (typeof window === 'undefined') return;

    this.logger = new Logger();
    this.internalStorage = new InternalStorage(this.logger);
    this.emitter = new Emitter(this.logger);
    this.client = new WSClient(this.logger, this.emitter, this.internalStorage, this.onReconnect);

    this.channelStatus = IStatus.IDLE;
    this.messageStatus = new Map();
  }

  private onReconnect = () => {
    const { currentSubscribedChannelID, misc } = this.internalStorage.data;
    this.logger.info('On reconnect ws - getting channels and messages');

    this.onGetChannels({ group_path: misc.groupPath, is_favorite: 1 });

    for (const channel_id of currentSubscribedChannelID) {
      this.onGetMessages({ channel_id }, [IChannelMessageMode.BEFORE]);
    }
  };

  // Must get group path before getting others
  private _subcribeGettingGroupPath = () => {
    if (this.internalStorage.data.misc.groupPath) return;
    return this.emitter.internalSubscribe(WsListenerType.INTERNAL_GETTING_GROUP_PATH);
  };

  // Prevent multiple request to get channels at the same time
  private _subcribeChannelsStatus = () => {
    return this.emitter.internalSubscribe(WsListenerType.INTERNAL_GETTING_CHANNEL);
  };

  // Prevent multiple request to get messages in the same channel at the same time
  private _subcribeGetMessages = (channelID: IChannelID) => {
    return this.emitter.internalSubscribe(WsListenerType.INTERNAL_GETTING_MESSAGE, channelID);
  };

  // Remove messages cache after CACHE_MESSAGES_MS if user unsubcribe channel
  private onSubcribeChannelMessages = (params: ISubcribeParams, channelID: IChannelID) => {
    this.internalStorage.removeTimeoutIfExist(channelID);

    return this.emitter.subscribe({
      ...params,
      onUnsubscribe: () => {
        this.internalStorage.setCurrentSubscribedChannelID((prev) => prev.filter((id) => id !== channelID));
        this.internalStorage.removeMessagesCache(channelID);
      },
    });
  };

  private onGetChannels = async (params: IGetChannelsParams) => {
    await this._subcribeGettingGroupPath();

    if (this.channelStatus === IStatus.LOADING) return await this._subcribeChannelsStatus();
    if (this.channelStatus === IStatus.SUCCESS && !params?.before) return;

    this.channelStatus = IStatus.LOADING;
    const res = await getChannels<IChannels>({
      ...params,
      group_path: this.internalStorage.data.misc.groupPath,
    });

    if (res.error) return showMessage.error(res.error || 'Có lỗi xảy ra, vui lòng thử lại sau');

    this.channelStatus = IStatus.SUCCESS;
    this.internalStorage.setChannels(res?.data);

    this.logger.info('Get channels', res.data);
    this.emitter.emitChange(WsListenerType.INTERNAL_GETTING_CHANNEL);
    this.emitter.emitChange(WsListenerType.CHANNELS);
  };

  private onGetChannelInfo = async ({ channel_id }: IGetChannelsInfoParams) => {
    const group_path = this.internalStorage.data.misc.groupPath;

    // Wait for list channels first, if channel not found, get channel info
    await this.onGetChannels({ group_path, is_favorite: 1 });

    let currentChannel = this.internalStorage.data.channels.find(
      (channel) => channel.channel_id === channel_id
    );

    if (!currentChannel) {
      const res = await getChannelsInfo({ channel_id, is_favorite: 1, group_path });

      if (res.error) {
        showMessage.error(res.error || 'Có lỗi xảy ra, vui lòng thử lại sau');
        return {} as IChannelData;
      }

      this.internalStorage.setChannels(res?.data);
      currentChannel = res?.data?.[0] || ({} as IChannelData);
    }

    return currentChannel;
  };

  private onSubcribeChannel = async ({ channel_id }) => {
    await this._subcribeGettingGroupPath();

    if (!channel_id) return this.logger.error('channel_id is required to subscribe');

    const { token, user } = this.internalStorage.data.misc;

    try {
      // If ws is connected, send subcribe event to ws
      // otherwise, save channel_id to subscribe later when ws is connected
      if (this.client.isWsConnected) {
        this.client.wsClient?.send(`${token}|sub|chats_channel_${channel_id}`);
        this.client.wsClient?.send(`${token}|sub|chats_user_${user?.user_id}`);
      }
      this.internalStorage.setCurrentSubscribedChannelID((prev) => [...prev, channel_id]);
    } catch (error) {
      this.internalStorage.setCurrentSubscribedChannelID((prev) => [...prev, channel_id]);
      this.logger.error('Error when subscribing channel', channel_id, error);
    }

    const currentChannel = await this.onGetChannelInfo({ channel_id });

    // Won't get messages if user has subscribed before
    if (!currentChannel?.has_subscribed) {
      this.internalStorage.setChannelMessages(channel_id, []);
      this.internalStorage.setChannelMessageMode(channel_id, [IChannelMessageMode.BEFORE]);
      this.emitter.emitChange(WsListenerType.CHANNEL_MESSAGE_MODE, channel_id);

      await this.onGetMessages({ channel_id, limit: MESSAGES_LIMIT });

      currentChannel.has_subscribed = true;
    }
  };

  private onGetMessages = async (params: IHandleGetListMessageParams, mode?: IChannelMessageMode[]) => {
    await this._subcribeGettingGroupPath();

    if (this.messageStatus.get(params.channel_id) === IStatus.LOADING) {
      await this._subcribeGetMessages(params.channel_id);
      return;
    }

    this.messageStatus.set(params.channel_id, IStatus.LOADING);

    if (mode) {
      this.internalStorage.setChannelMessages(params.channel_id, []);
      this.internalStorage.setChannelMessageMode(params.channel_id, mode);
      this.emitter.emitChange(WsListenerType.CHANNEL_MESSAGE_MODE, params.channel_id);
    }

    const newParams = {
      ...params,
      before: params?.before
        ? this.internalStorage.data.channelMessages.get(params.channel_id)?.[0]?.score
        : undefined,
      after: params?.after
        ? this.internalStorage.data.channelMessages.get(params.channel_id)?.at(-1)?.score
        : undefined,
    };

    const res = await getListMessages<IChannelMessage[]>(newParams);

    if (res.error) return showMessage.error(res.error || 'Có lỗi xảy ra, vui lòng thử lại sau');

    const messages = convertMessages(res?.data, this.internalStorage.data.misc);

    this.internalStorage.setChannelMessages(params.channel_id, (prev) =>
      params?.after ? [...prev, ...messages] : [...messages, ...prev]
    );

    this.logger.info('Get messages', `- ChannelID: ${params?.channel_id}`, messages);

    if (res.data.length < MESSAGES_LIMIT) {
      if (params?.before) {
        this.internalStorage.setChannelMessageMode(params.channel_id, (prev) => {
          return prev
            .filter((mode) => mode !== IChannelMessageMode.BEFORE)
            .concat([IChannelMessageMode.MAX_BEFORE]);
        });
      }
      if (params?.after) {
        this.internalStorage.setChannelMessageMode(params.channel_id, (prev) => {
          return prev
            .filter((mode) => mode !== IChannelMessageMode.AFTER)
            .concat([IChannelMessageMode.MAX_AFTER]);
        });
      }
      this.emitter.emitChange(WsListenerType.CHANNEL_MESSAGE_MODE, params.channel_id);
    }

    this.emitter.emitChange(WsListenerType.MESSAGES, params.channel_id);
    this.messageStatus.set(params.channel_id, IStatus.IDLE);
  };

  private createFileParams = (files: File[], channelID: IChannelID) => {
    const refID = window.crypto.randomUUID();

    const tempMsg: IChannelMessage = {
      attachments: files.map((file) => ({
        id: uid(6),
        url: URL.createObjectURL(file),
        mime: file.type,
        name: file.name,
        ext: file.name.split('.').pop(),
      })),
      channel_id: channelID,
      text: '',
      created_at: refID,
      msg_type: 'text',
      is_current_user: true,
      id: refID,
    };

    this.internalStorage.setChannelMessages(channelID, (prev) => [...prev, tempMsg]);

    return {
      channel_id: channelID,
      msg_type: IMsgType.TEXT,
      ref_id: refID,
      text: '',
      file_id: null,
    };
  };

  private createTextParams = (message: string, channelID: IChannelID, quoteMessage: IChannelMessage) => {
    const refID = window.crypto.randomUUID();

    const tempMsg: IChannelMessage = {
      channel_id: channelID,
      text: message,
      created_at: refID,
      msg_type: quoteMessage ? 'quote_message' : 'text',
      is_current_user: true,
      id: refID,
      quote_message: quoteMessage,
    };

    this.internalStorage.setChannelMessages(channelID, (prev) => [...prev, tempMsg]);

    return {
      channel_id: channelID,
      msg_type: quoteMessage ? IMsgType.QUOTE_MESSAGE : IMsgType.TEXT,
      quote_message_id: quoteMessage?.id,
      ref_id: refID,
      text: message,
      file_id: null,
    };
  };

  private handleSend: IHandleSend = async (params) => {
    const { channel_id: channelID, ref_id: refID } = params || {};

    this.logger.info('Sending message', params);

    const res = await sendMessage(params);

    if (res?.error) {
      this.internalStorage.setChannelMessages(channelID, (prev) =>
        prev.map((msg) => {
          if (msg.id === refID) {
            msg.error = res.error;
          }

          return msg;
        })
      );
      return this.emitter.emitChange(WsListenerType.MESSAGES, channelID);
    }

    this.internalStorage.setChannelMessages(channelID, (prev) => prev.filter((msg) => msg.id !== refID));

    return res?.data;
  };

  private handleSendMessage: IHandleSendMessages = async ({
    quoteMessage,
    message,
    files,
    channelID,
    onShowTempMessage,
  }) => {
    if (!channelID) return this.logger.error('ChannelID is required to send message');
    const tempMsgs = [];

    const textParams = message && this.createTextParams(message, channelID, quoteMessage);
    const fileParams = files.length > 0 && this.createFileParams(files, channelID);

    // Show temp message before sending
    this.emitter.emitChange(WsListenerType.MESSAGES, channelID);

    // If user want to call some functions before sending message (e.g: scroll to bottom)
    if (onShowTempMessage) onShowTempMessage();

    // Message come first
    if (message) {
      const msg = await this.handleSend(textParams);
      tempMsgs.push(msg);
    }

    if (files.length > 0) {
      const formData = new FormData();
      files.map((attach) => formData.append('attachment', attach));

      const { data, error } = await uploadFile(formData);

      if (error) {
        showMessage.error(error);
        return;
      }

      const msg = await this.handleSend({ ...fileParams, file_id: data?.map((file) => file?.id)?.join(',') });
      tempMsgs.push(msg);
    }
    // If ws is not connected, emit change to update UI
    // Otherwise, ws event will handle this
    if (!this.client.isWsConnected) {
      this.logger.info('Updating UI from API response');
      const newMessages = convertMessages(tempMsgs.reverse(), this.internalStorage.data.misc);
      this.internalStorage.setChannelMessages(channelID, (prev) => [...prev, ...newMessages]);
      this.internalStorage.setChannels((prev) => {
        const channel = prev.find((c) => c?.channel_id === channelID);
        if (channel) {
          channel.last_message = tempMsgs.at(-1);
        }
        return prev;
      });

      this.emitter.emitChange(WsListenerType.MESSAGES, channelID);
      this.emitter.emitChange(WsListenerType.CHANNEL, channelID);
    }
  };

  private setGroupPath = (groupPath: IGroupPath) => {
    this.internalStorage.setMisc((prev) => ({ ...prev, groupPath }));
    this.emitter.emitChange(WsListenerType.INTERNAL_GETTING_GROUP_PATH);
  };

  public useIsWsConnected = () =>
    useBaseIsWsConnected({
      baseIsConnected: this.client?.isWsConnected,
      subscribe: this.emitter?.subscribe,
    });

  public useChannels = () =>
    useBaseChannels({
      channels: this.internalStorage?.data?.channels,
      subscribe: this.emitter?.subscribe,
      onGetChannels: this.onGetChannels,
    });

  public useChannelMessage = (channelID: IChannelID) =>
    useBaseChannelMessage({
      channelID,
      channelMessages: this.internalStorage?.data?.channelMessages,
      subcribeChannel: this.onSubcribeChannel,
      subscribe: (params) => this.onSubcribeChannelMessages(params, channelID),
    });

  public useChannel = (channelID: IChannelID) =>
    useBaseChannel({
      channelID,
      subscribe: this.emitter?.subscribe,
      _subcribeChannelsStatus: this._subcribeChannelsStatus,
      internalStorage: this.internalStorage,
    });

  public useChannelMsgMode = (channelID: IChannelID) =>
    useBaseChannelMsgMode({
      channelID,
      subscribe: this.emitter?.subscribe,
      internalStorage: this.internalStorage,
    });

  private initializeWs = (userInfo: IUser) => this.client.initializeWs(userInfo);

  get chatInstance() {
    return {
      handleSetGroupPath: this.setGroupPath,
      handleGetMessages: this.onGetMessages,
      handleSendMessage: this.handleSendMessage,
      initializeWs: this.initializeWs,
    };
  }
}

export const {
  chatInstance,

  useChannelMsgMode,
  useChannel,
  useChannels,
  useChannelMessage,
  useIsWsConnected,
} = new ChatModule();
