import { useEffect, useState } from 'react';
import { WsListenerType } from '../constants';
import { IChannels } from '../interfaces';
import { IGetChannelsParams } from '../services/gamp-chat.service';
import { ISubcribeParams } from '../modules/Emitter';

interface IUseBaseChannels {
  channels: IChannels;
  subscribe: (params: ISubcribeParams) => any;
  onGetChannels: (params: IGetChannelsParams) => Promise<unknown>;
}

export const useBaseChannels = ({ channels, subscribe, onGetChannels }: IUseBaseChannels) => {
  const [_, reRender] = useState({});
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    const listener = () => reRender({});
    const unSubcribe = subscribe({ type: WsListenerType.CHANNELS, listener });

    const onSubChannel = async () => {
      setLoading(true);
      await onGetChannels({ is_favorite: 1 });
      setLoading(false);
    };

    onSubChannel();

    return () => {
      unSubcribe();
    };
  }, []);

  const getChannels = (params: IGetChannelsParams) => onGetChannels({ is_favorite: 1, ...params });

  return { channels, getChannels, loading };
};
