import { useChannelMessage } from '@/configs/Chat';

import React, { useEffect, useState } from 'react';
import { Skeleton } from '@/UI';
import { WidgetHeader } from './WidgetHeader';
import { WidgetFooter } from './WidgetFooter';
import { ChatWrapper } from './ChatWrapper';

export const ChatContainer = ({ channelID }) => {
  return (
    <div className="h-[400px] w-[300px] bg-th-background shadow-lg rounded-t-xl flex flex-col">
      <WidgetHeader channelID={channelID} />
      <ListMessages channelID={channelID} />
      <WidgetFooter channelID={channelID} />
    </div>
  );
};

const ListMessages = ({ channelID }) => {
  const { channelMessages, loading } = useChannelMessage(channelID);

  return (
    <div className="h-full bg-th-gray-50 flex flex-col overflow-hidden">
      {loading || typeof loading === 'undefined' ? (
        <SkeletonLoading />
      ) : (
        <ChatWrapper channelMessages={channelMessages} channelID={channelID} />
      )}
    </div>
  );
};

const SkeletonLoading = () => {
  return (
    <div className="px-2 pt-1 overflow-hidden h-full">
      {Array.from({ length: 3 }).map((_, index) => (
        <Skeleton active key={index} />
      ))}
    </div>
  );
};
