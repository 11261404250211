import { IUserInfo } from '@/configs/Auth/interfaces';
import { ISendMessageParams } from '../services/gamp-chat.service';

/* eslint-disable no-unused-vars */
export type IUser = IUserInfo;
export enum IGroupPath {
  CHAT_GAM = 'chat-gam',
  CHAT_GAM_INC = 'chat-gam-incognito',
}

export enum IMsgType {
  TEXT = 'text',
  QUOTE_MESSAGE = 'quote_message',
}

export type IChannelID = string;

export type IGroupImage = {
  id: string;
  avatar: string;
  fullname: string;
  user_type: string;
};

export type IChannelData = {
  avatar?: string;
  channel_id: string;
  channel_name: string;
  channel_mode: string;
  channel_type: string;
  gr: number;
  created: string;
  count_member: number;
  gid: string;
  is_favorite: number;
  favorite: {
    id: number;
    is_draft: number;
    is_fav: number;
    priority: number;
  };
  is_notification: number;
  role: number;
  count_message_unread: number;
  total_member_active: number;
  total_media: number;
  is_show_share_link: number;
  score: string;
  last_message: IChannelMessage;
  group_images: IGroupImage[];
  tags: {
    id: number;
    name: string;
    group: string;
    color: string;
    background_color: string;
    ref: string;
    order: number;
    status: number;
  }[];
  permission: {
    call: number;
    message: number;
    save_media: number;
  };
  members: IUser[];
  author: {
    id: string;
    user_key: string;
    user_type: string;
    user_id: string;
    username: string;
    fullname: string;
    status_id: number;
  };

  has_subscribed?: boolean;
  is_minimized?: boolean;
};

export type IChannelMessage = {
  id?: string;
  msg_type: string;
  channel_id: string;
  channel_mode?: string;
  channel_type?: string;
  text: string;
  status?: number;
  created_at: string;
  score?: string;
  is_pin?: number;
  sender?: IUser;
  attachments?: { id: string; name?: string; size?: number; mime?: string; url: string }[];

  is_current_user?: boolean;
  created_at_text?: string;
  error?: string;
  quote_message?: IChannelMessage;
};

export type WsCallback = (isConnected?: boolean) => void;
export type WSMapCallback = Map<IChannelID, WsCallback[]>;

export type IChannelMessages = Map<IChannelID, IChannelMessage[]>;
export type IChannels = IChannelData[];
export type IMisc = {
  token: string;
  user: IUser;
  groupPath: IGroupPath;
};

export type ISetChannel = (channels: IChannels | ((prev: IChannels) => IChannels)) => void;

export type ISetChannelMessages = (
  channelID: IChannelID,
  messages: IChannelMessage[] | ((prev: IChannelMessage[]) => IChannelMessage[])
) => void;

export type IHandleSend = (params: ISendMessageParams) => Promise<void>;

export type IHandleSendMessages = ({
  message,
  files,
}: {
  message: string;
  channelID: IChannelID;
  quoteMessage?: IChannelMessage;
  files?: File[];
  onShowTempMessage?: () => void;
}) => Promise<void>;

export interface IGetChannelsInfoParams {
  channel_id: IChannelID;
}

export interface IHandleGetListMessageParams {
  channel_id: string;
  middle?: string;
  after?: boolean;
  before?: boolean;
  limit?: number;
}
