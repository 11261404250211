import { searchValueOptions } from '@/utils/functions';
import { Checkbox, Select } from '@/UI';
import React from 'react';

const STATUS_OPTIONS = [
  { label: 'Đang hoạt động', value: 'active' },
  { label: 'Chưa kiểm duyệt', value: 'inactive' },
];
const Option = ({ label, checked, className = '', onClick = null }) => (
  <div className={`flex items-center gap-1 ${className}`} onClick={onClick}>
    <Checkbox checked={checked} className="mr-2 checkbox-custom" />
    {label}
  </div>
);

const ShopStatusSelect = ({ value = undefined, onChange = undefined }) => {
  const isCheckAll = value?.length === STATUS_OPTIONS?.length;

  const onCheckALl = () => onChange(isCheckAll ? [] : STATUS_OPTIONS?.map((i) => i.value));

  const optionRender = (option) => <Option label={option.label} checked={value?.includes(option?.value)} />;

  const dropdownRender = (menu) => (
    <>
      <Option
        label="Tất cả"
        checked={isCheckAll}
        className={`py-2 px-[12px] cursor-pointer hover:bg-[#0000000A] rounded ${
          isCheckAll && 'font-semibold'
        }`}
        onClick={onCheckALl}
      />
      {menu}
    </>
  );

  return (
    <Select
      className="w-full"
      filterOption={searchValueOptions}
      value={value}
      placeholder="Trạng thái shop"
      onChange={onChange}
      optionRender={optionRender}
      mode="multiple"
      allowClear
      maxTagCount="responsive"
      options={STATUS_OPTIONS}
      dropdownRender={dropdownRender}
      popupClassName="select-all"
    />
  );
};

export default ShopStatusSelect;
