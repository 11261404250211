import Layout from '@/layouts/insight-solution/layout';
import { Button, Table } from '@/UI';
import { useState } from 'react';
import Filter from '@/modules/customer/components/Filter';
import { useRouter } from 'next/router';
import CreateShop from './components/CreateShop';
import OtherInfo from './components/OtherInfo';
import GeneralInfo from './components/GeneralInfo';
import { useQuery } from '@tanstack/react-query';
import { endpoints, getShops } from '@/services/Customer.service';
import { APIResponse } from '@/interfaces';
import { showMessage } from '@/components/messages/GMessage';

const Customer = () => {
  const [filters, setFilters] = useState({ page: 1, page_size: 10 });
  const [open, setOpen] = useState(false);
  const router = useRouter();

  const { data, refetch } = useQuery({
    queryKey: [endpoints.SHOPS, filters],
    queryFn: async () => {
      const { error, data, pagination }: APIResponse = await getShops({
        ...filters,
        page: filters?.page - 1,
      });

      if (error) {
        showMessage.error(error);
      }

      return { records: data, pagination };
    },
  });

  const columns: any = [
    {
      title: 'Thông tin chung',
      key: 'general_info',
      width: '45%',
      render: (_, record) => <GeneralInfo record={record} />,
    },
    {
      title: 'Thông tin khác',
      key: 'other_info',
      width: '45%',
      render: (_, record) => <OtherInfo record={record} />,
    },
    {
      title: 'Thao tác',
      key: 'action',
      align: 'center',
      width: '10%',
      render: (_, record) => (
        <Button
          className="bg-th-background-2"
          size="small"
          onClick={() => router.push(`/customer/${record?.id}/shop-detail`)}
        >
          Chi tiết
        </Button>
      ),
    },
  ];

  const handleOpen = () => setOpen((prev) => !prev);
  const handleChangePage = (page, page_size) => setFilters((prev) => ({ ...prev, page, page_size }));

  const paginationProps = {
    current: filters.page,
    pageSize: filters.page_size,
    onChange: handleChangePage,
    total: data?.pagination ? data?.pagination?.page_size * data?.pagination?.total_pages : 0,
    showSizeChanger: true,
  };

  return (
    <Layout
      title="Danh sách khách hàng GAM"
      groupBtns={
        <Button type="primary" onClick={handleOpen} ghost>
          + Thêm shop
        </Button>
      }
      filter={<Filter filters={filters} setFilters={setFilters} />}
      paginationProps={paginationProps}
    >
      <Table rowKey="id" dataSource={data?.records} bordered columns={columns} pagination={false} />
      <CreateShop open={open} onCancel={handleOpen} refetch={refetch} />
    </Layout>
  );
};

export default Customer;
