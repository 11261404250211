import { useQueryClient } from '@tanstack/react-query';

export function useRefetch(key) {
  const queryClient = useQueryClient();

  const refetch = () => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey?.includes(key),
    });
  };

  return { refetch };
}
