import { useChannelMessage } from '@/configs/Chat';
import SkeletonLoading from '../SkeletonLoading';
import { ChatWrapper } from './MessageContainer';
import { useChat } from '../..';

export const MessagesList = () => {
  const { channelID } = useChat();
  const { channelMessages, loading } = useChannelMessage(channelID);

  if (loading || typeof loading === 'undefined') {
    return <SkeletonLoading />;
  }

  return <ChatWrapper channelMessages={channelMessages} channelID={channelID} />;
};
