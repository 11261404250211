import { ReactElement } from 'react';
import { NextPageWithLayout } from '../_app';
import DefaultLayout from '@/layouts/default';
import Customer from '@/modules/customer';

const CustomerPage: NextPageWithLayout = () => {
  return <Customer />;
};

CustomerPage.getLayout = function getLayout(page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export default CustomerPage;

export const getServerSideProps = async ({ req }) => {
  const userAgent = req.headers['user-agent'];
  const isMobile = Boolean(
    userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)
  );

  return {
    props: {
      deviceType: isMobile ? 'mobile' : 'desktop',
    },
  };
};
