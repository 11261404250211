import { getRouteLabel, NAV_ITEMS } from '@/layouts/components/navitems';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Head from 'next/head';
import { ArrowDownIcon, Check, GAM } from '@/components/icons';
import UserBtn from '@/layouts/components/UserBtn';
import { Dropdown, MenuProps } from '@/UI';
import Link from 'next/link';

export default function AppHeader() {
  const router = useRouter();
  const routes = router.asPath.split('/') || [];

  const routeLabel = getRouteLabel(`/${routes[1]}`, `/${routes[2]}`);

  return (
    <>
      <Head>
        <title>{routeLabel}</title>
      </Head>
      <div className="flex items-center justify-between w-full border-0 border-b border-solid border-th-border bg-th-background px-4">
        <div className="flex items-center gap-2 flex-1">
          <span className="flex items-center cursor-pointer mr-2 py-2" onClick={() => router.push('/')}>
            <GAM />
          </span>
          <div className="flex-1 flex gap-2 items-center text-text-secondary cursor-pointer">
            {NAV_ITEMS.map((item) => (
              <NavItem item={item} key={item.key} />
            ))}
          </div>
        </div>
        <UserBtn />
      </div>
    </>
  );
}

export const NavItem = ({ item }) => {
  const router = useRouter();
  const { key, label, children = [] } = item;
  const routes = router.asPath.split('/') || [];
  const isCurrentNav = `/${routes[1]}` === key;
  const subItems: MenuProps['items'] = children
    .map(({ key, label }, index) => [
      index !== 0 && { type: 'divider' },
      {
        key,
        label: (
          <span className="flex items-center justify-between w-full gap-4 leading-5 py-1">
            {label}
            <Check
              className={isCurrentNav && `/${routes[2]}` === key ? 'text-th-primary' : 'text-transparent'}
            />
          </span>
        ),
      },
    ])
    .flat()
    .filter(Boolean);

  return (
    <Dropdown
      arrow={false}
      trigger={['hover']}
      placement="bottom"
      disabled={children?.length === 0}
      menu={{
        items: subItems,
        className: 'custom-menu-dropdown',
        onClick: (menuItem) => {
          if (isCurrentNav && `/${routes[2]}` === menuItem.key) return;
          router.push(key + menuItem.key);
        },
      }}
    >
      <Link
        className={classNames(
          'px-3 pt-5 pb-[18px] flex items-center justify-center gap-1 leading-6 border-0 border-b-2 border-solid border-transparent',
          {
            'text-th-primary': isCurrentNav,
            'text-th-text-secondary hover:border-th-primary': !isCurrentNav,
          }
        )}
        href={key}
        onClick={(e) => isCurrentNav && e.preventDefault()}
      >
        {label} {children?.length > 0 && <ArrowDownIcon className="text-sm" />}
      </Link>
    </Dropdown>
  );
};
