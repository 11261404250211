import { useEffect, useState } from 'react';
import { WsListenerType } from '../constants';
import { IChannelID, IChannelMessages } from '../interfaces';
import { ISubcribeParams } from '../modules/Emitter';
import { flushSync } from 'react-dom';

interface IUseBaseChannelMessage {
  channelID: IChannelID;
  channelMessages: IChannelMessages;
  subcribeChannel: ({ channel_id }) => Promise<void>;
  subscribe: (params: ISubcribeParams) => any;
}

export const useBaseChannelMessage = ({
  channelID,
  subcribeChannel,
  subscribe,
  channelMessages,
}: IUseBaseChannelMessage) => {
  const [_, reRender] = useState({});
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    if (!channelID) return;

    const listener = () => flushSync(() => reRender({}));

    const unSubcribe = subscribe({ type: WsListenerType.MESSAGES, listener, channelID });

    const onSubChannel = async () => {
      setLoading(true);
      await subcribeChannel({ channel_id: channelID });
      setLoading(false);
    };

    onSubChannel();

    return () => {
      unSubcribe();
    };
  }, [channelID]);

  return { channelMessages: channelMessages?.get(channelID), loading };
};
