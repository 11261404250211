import { IColor } from './themeConfig';

export const LIGHT: IColor = {
  display: 'light',

  text: 'hsl(0, 0%, 0%)',
  white: '#fff',
  'text-secondary': '#5C5C5C',
  'text-hint': '#7A7A7A',
  'text-strock': '#B3B3B3',
  neutral: '#ABABAB',
  error: '#eb5757',

  border: '#e8e8e8',
  background: 'hsl(0, 0%, 100%)',
  'background-1': '#f8f8f8',
  'background-2': '#f4f4f4',
  'background-overlay': 'hsl(0, 0%, 95%)',
  'background-tag': 'hsl(0, 0%, 0%, 6%)',
  'background-document': '#ABABAB',

  primary: '#00904a',
  'primary-25': '#F2F8F4',
  'primary-50': '#e5f4ed',
  'primary-75': '#bacfc1',
  'primary-100': '#b3dec9',
  'primary-150': '#4DB281',
  'primary-200': '#33a66e',
  'primary-500': '#006534',

  'gray-50': 'hsl(0, 0%, 95%)',
  'gray-80': 'hsl(0, 0%, 92%)',
  'gray-100': 'hsl(0, 0%, 90%)',
  'gray-150': 'hsl(0, 0%, 87%)',
  'gray-200': 'hsl(0, 0%, 80%)',
  'gray-300': 'hsl(0, 0%, 70%)',
  'gray-400': 'hsl(0, 0%, 60%)',
  'gray-500': 'hsl(0, 0%, 50%)',
  'gray-600': 'hsl(0, 0%, 40%)',
  'gray-700': 'hsl(0, 0%, 30%)',
  'gray-800': 'hsl(0, 0%, 20%)',
  'gray-900': 'hsl(0, 0%, 10%)',

  'blue-50': 'hsl(227, 84%, 95%)',
  'blue-100': 'hsl(227, 100%, 90%)',
  'blue-400': 'hsl(227, 84%, 50%)',
  'blue-500': 'hsl(227, 84%, 40%)',
  'blue-600': 'hsl(227, 84%, 30%)',

  'green-200': 'hsl(141, 70%, 85%)',
  'green-500': 'hsl(141, 70%, 45%)',
  'green-600': 'hsl(141, 70%, 35%)',

  'red-25': 'hsl(0, 84%, 95%)',
  'red-500': 'hsl(0, 84%, 50%)',
  'red-600': 'hsl(0, 84%, 60%)',
  'red-700': 'hsl(0, 84%, 70%)',

  'cyan-50': 'hsl(188, 86%, 85%)',
  'cyan-400': 'hsl(188, 86%, 60%)',
  'cyan-800': 'hsl(188, 86%, 20%)',

  'orange-500': 'hsl(33, 90%, 50%)',
  'shadow-1': 'rgb(0 0 0 / 0.1)',
  'shadow-2': 'rgb(0 0 0 / 0.2)',
};

export const DARK: IColor = {
  display: 'dark',

  text: 'hsl(0, 0%, 95%)',
  'text-secondary': '#A6A6A5',
  'text-hint': '#9A9A9A',
  'text-strock': '#4D4D4D',
  error: '#F8503D',

  border: '#4a4a4a',
  background: '#141414',
  'background-1': 'hsl(0, 0%, 15%)',
  'background-2': 'hsl(0, 0%, 12%)',
  'background-overlay': 'hsl(0, 0%, 15%)',
  'background-tag': 'hsl(0, 0%, 100%, 12%)',
  'background-document': '#ABABAB',

  primary: '#00DB4D',
  'primary-25': '#F2F8F4',
  'primary-50': '#e5f4ed',
  'primary-100': '#b3dec9',
  'primary-150': '#4DB281',
  'primary-200': '#33a66e',
  'primary-500': '#006534',

  'gray-50': 'hsl(0, 0%, 10%)',
  'gray-100': 'hsl(0, 0%, 20%)',
  'gray-150': 'hsl(0, 0%, 25%)',
  'gray-200': 'hsl(0, 0%, 30%)',
  'gray-300': 'hsl(0, 0%, 40%)',
  'gray-400': 'hsl(0, 0%, 50%)',
  'gray-500': 'hsl(0, 0%, 60%)',
  'gray-600': 'hsl(0, 0%, 70%)',
  'gray-700': 'hsl(0, 0%, 80%)',
  'gray-800': 'hsl(0, 0%, 90%)',
  'gray-900': 'hsl(0, 0%, 95%)',

  'blue-50': 'hsl(227, 84%, 10%)',
  'blue-400': 'hsl(227, 84%, 50%)',
  'blue-500': 'hsl(227, 84%, 60%)',
  'blue-600': 'hsl(227, 84%, 70%)',

  'green-200': 'hsl(141, 70%, 15%)',
  'green-500': 'hsl(141, 70%, 55%)',
  'green-600': 'hsl(141, 70%, 65%)',

  'red-25': 'hsl(0, 84%, 95%)',
  'red-500': 'hsl(0, 84%, 50%)',
  'red-600': 'hsl(0, 84%, 40%)',
  'red-700': 'hsl(0, 84%, 30%)',

  'cyan-50': 'hsl(188, 86%, 15%)',
  'cyan-400': 'hsl(188, 86%, 50%)',
  'cyan-800': 'hsl(188, 86%, 80%)',

  'orange-500': 'hsl(33, 90%, 50%)',
};
