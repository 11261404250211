import { chatInstance, useChannelMsgMode } from '@/configs/Chat';
import { MESSAGES_LIMIT } from '@/configs/Chat/constants';
import dayts from '@/utils/time';
import { Skeleton } from '@/UI';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Message } from './Message';
import { ImageViewer } from '../ImageViewer';
import { IChannelMessageMode } from '@/configs/Chat/modules/InternalStorage';
import { ArrowLeftAlt } from '@/components/icons';

export const ChatWrapper = ({ channelMessages, channelID }) => {
  const [current, setCurrent] = useState<number>();
  const mode = useChannelMsgMode(channelID);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);

  useEffect(() => {
    const element = containerRef.current;

    const cb = async () => {
      const isBefore = element.scrollHeight + element.scrollTop - element.clientHeight < 50;
      const isAfter = element.scrollTop > -50;

      if (!loading) {
        if (mode.includes(IChannelMessageMode.BEFORE) && isBefore) {
          setLoading(true);
          await chatInstance.handleGetMessages({
            channel_id: channelID,
            limit: MESSAGES_LIMIT,
            before: true,
          });
          element.scrollTop = element.scrollTop + 10;
          setLoading(false);
        }

        if (mode.includes(IChannelMessageMode.AFTER) && isAfter) {
          setLoading(true);
          await chatInstance.handleGetMessages({
            channel_id: channelID,
            limit: MESSAGES_LIMIT,
            after: true,
          });
          element.scrollTop = element.scrollTop - 20;
          setLoading(false);
        }
      }

      setShowScrollToBottom(element.scrollTop < -200);
    };

    element.addEventListener('scroll', cb);

    return () => {
      element.removeEventListener('scroll', cb);
    };
  }, [channelID, loading, mode]);

  const imageList = useMemo(() => {
    return channelMessages?.reduce((acc, message) => {
      if (message?.attachments?.length) {
        const images = message.attachments.filter((attachment) => attachment.mime.includes('image/'));
        return [
          ...acc,
          ...images.map((image) => ({
            url: image.url,
            id: image.id,
          })),
        ];
      }
      return acc;
    }, []);
  }, [channelMessages]);

  const onClickImg = (id) => {
    const index = imageList.findIndex((image) => image.id === id);
    setCurrent(index);
  };

  return (
    <div className="relative h-full w-full overflow-hidden">
      {showScrollToBottom && (
        <button
          className="absolute bottom-4 w-[24px] h-[24px] bg-th-gray-50 rounded-full left-1/2 -translate-x-1/2 cursor-pointer z-50 border border-solid border-th-gray-100 flex items-center justify-center"
          onClick={async () => {
            const element = containerRef.current;

            if (mode.includes(IChannelMessageMode.AFTER)) {
              await chatInstance.handleGetMessages(
                {
                  channel_id: channelID,
                  limit: MESSAGES_LIMIT,
                },
                [IChannelMessageMode.BEFORE]
              );
            }

            element.scrollTo({
              top: element.scrollHeight,
              behavior: 'smooth',
            });
          }}
        >
          <ArrowLeftAlt className="-rotate-90 scale-75" />
        </button>
      )}
      <div
        className="px-3 pt-1 overflow-auto h-full relative flex flex-col-reverse"
        id={`chat-wrapper-${channelID}`}
        ref={containerRef}
      >
        {loading && (
          <div className="w-full flex flex-col gap-2">
            <div className="w-full flex justify-start">
              <Skeleton.Input active className="w-[80%]" />
            </div>
            <div className="w-full flex justify-end">
              <Skeleton.Input active className="w-[80%] text-right" />
            </div>
          </div>
        )}

        <ImageViewer
          imageList={imageList}
          currentIndex={current}
          onChangeIndex={(index) => setCurrent(index)}
        >
          <div className="flex flex-col gap-1 w-full mb-auto">
            {channelMessages?.map((message, index) => {
              const hasNextMessageFromSameUser =
                index === 0
                  ? false
                  : channelMessages[index]?.sender?.id === channelMessages[index - 1]?.sender?.id;

              const currentTimeMessage = dayts(channelMessages[index]?.created_at).unix();
              const nextTimeMessage = dayts(channelMessages[index - 1]?.created_at).unix();
              const shouldShowTime = index === 0 ? true : currentTimeMessage - nextTimeMessage > 1800;

              return (
                <Message
                  key={message?.created_at}
                  index={index}
                  message={message}
                  shouldShowTime={shouldShowTime}
                  hasNextMessageFromSameUser={hasNextMessageFromSameUser}
                  onClickImg={onClickImg}
                />
              );
            })}
          </div>
        </ImageViewer>
      </div>
    </div>
  );
};
