/* eslint-disable no-case-declarations */
import axios, { AxiosResponse, AxiosError } from 'axios';
import RequestObserver, { APP_URL, CustomConfig, REFRESH_URL } from './RequestObserver';
import { toQueryParams } from '@/configs/Auth/utils/functions';
import { CustomAxiosInstance as AxiosInstance } from '@/interfaces';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const JSONbig = require('json-bigint');

const JSONbigString = JSONbig({ storeAsString: true });
const NEW_COD_URL = process.env.NEXT_PUBLIC_OS_API_URL;
const errorMessages = 'Có lỗi trong quá trình thực thi';
const observer = new RequestObserver();

const errorCallback = (status: number, dataError: any) => {
  const error = dataError?.message ?? errorMessages;
  delete dataError?.message;
  delete dataError?.success;

  return {
    status: status ?? 500,
    error,
    ...dataError,
  };
};

const handleGetToken = async () => {
  const currentPath = window.location.href.replace(window.location.origin, '');
  const params = toQueryParams({ current: currentPath });
  window.location.href = `${APP_URL}/api/auth/login${params}`;
};

const handle403Status = async (dataError, config) => {
  if (config.url === REFRESH_URL) {
    handleGetToken();
  }
  return errorCallback(403, dataError);
};

class ApiClient {
  private readonly api: AxiosInstance;

  constructor(baseURL: string, headers = {}) {
    this.api = axios.create({
      baseURL: baseURL || NEW_COD_URL || '',
      timeout: 30000,
      transformResponse: [(data) => JSONbigString.parse(data)],
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Token-Type': 'User_Token',
      },
    }) as AxiosInstance;

    this.api.interceptors.request.use(
      (config) => this.handleInterceptReq(config, headers),
      (error) => Promise.reject(error)
    );
    this.api.interceptors.response.use(this.handleInterceptRes, this.handleInterceptResError);
  }

  private handleInterceptReq = async (config: CustomConfig, headers) => {
    const signal = await observer.getReqSignal(config);

    const token = localStorage.getItem('token') ?? '';
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['app-version'] = process.env.buildId;

    config.headers = { ...config.headers, ...headers };
    config.signal = signal;

    return config;
  };

  private handleInterceptRes = ({ data }: AxiosResponse) =>
    data.success === false ? { ...data, status: 400, error: data?.message ?? errorMessages } : data;

  private handleInterceptResError = async (error: AxiosError) => {
    const config: CustomConfig = error.config;
    const resError = error.response;
    const dataError: any = resError?.data;
    const isCancelNotFromUser = axios.isCancel(error) && observer.getSuspendedStatus();

    if (config?._retry) return errorCallback(resError?.status, dataError);

    if (resError?.status === 401 || isCancelNotFromUser) {
      config._retry = true;

      return this.api(config);
    }

    if (resError?.status === 403) {
      return handle403Status(dataError, config);
    }

    return errorCallback(resError?.status, dataError);
  };

  public getInstance = () => this.api;
}

export default ApiClient;
