import { ChatImg, ChatLink, ThreeDots } from '@/components/icons';
import { useAuth } from '@/configs/Auth/client';
import { useChannel, useChannels } from '@/configs/Chat';
import { Skeleton, Dropdown, MenuProps } from '@/UI';
import dayts from '@/utils/time';
import React from 'react';
import cn from 'classnames';
import { IChannelData, IChannelMessage } from '@/configs/Chat/interfaces';
import { useChat } from '..';
import ChannelAvatar from '@/modules/chat/components/ChannelAvatar';

export const ChatChannel = () => {
  const { channels, loading } = useChannels();
  const { channelID, setCurrentChannelID, open, setOpen, addChannel } = useChat();

  const createMenuItems = (channel: IChannelData): MenuProps['items'] => [
    {
      key: '1',
      label: <div onClick={(event) => onCreateWidget(event, channel)}>Thu gọn</div>,
    },
  ];

  const onCreateWidget = (e: React.MouseEvent, channel: IChannelData) => {
    e.stopPropagation();
    addChannel(channel);
  };

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex flex-col h-full overflow-auto">
        {loading && (
          <div className="px-2 pt-1 gap-3 flex flex-col overflow-hidden h-full">
            {Array.from({ length: 5 }).map((_, index) => (
              <Skeleton avatar active key={index} />
            ))}
          </div>
        )}

        {channels?.map((channel) => {
          const avatarUrl = channel?.avatar ?? channel?.group_images;

          return (
            <div
              key={channel?.channel_id}
              onClick={() => {
                setOpen(true);
                setCurrentChannelID(channel?.channel_id);
              }}
              className={cn(
                'flex w-full pl-4 py-3 gap-4 items-center hover:bg-th-primary-50 bg-th-gray-50 dark:bg-th-background-1 relative cursor-pointer',
                {
                  'bg-th-primary-50 dark:bg-th-background-3': channelID === channel?.channel_id,
                }
              )}
            >
              <div className="relative w-[48px] h-[48px] shrink-0 ">
                <ChannelAvatar src={avatarUrl} name={channel?.channel_name} isOnline />
              </div>
              {open && (
                <div className="relative flex flex-col gap-2 overflow-hidden h-[48px] pr-8 w-full">
                  <div className="truncate font-semibold text-th-text">
                    {channel?.channel_name ||
                      `${channel?.channel_type.toLocaleUpperCase()} ${channel?.channel_id}`}
                  </div>
                  <ChannelLastMessage message={channel?.last_message} id={channel?.channel_id} />

                  <Dropdown
                    menu={{ items: createMenuItems(channel) }}
                    placement="bottomRight"
                    trigger={['hover']}
                  >
                    <div className="pl-5 absolute top-[2px] right-5" onClick={(e) => e.stopPropagation()}>
                      <ThreeDots />
                    </div>
                  </Dropdown>
                  <div className="absolute bottom-[2px] right-5 text-sm text-th-gray-500">
                    {dayts(channel?.last_message?.created_at).format('HH:mm')}
                  </div>
                </div>
              )}

              <div className="absolute bottom-0 right-5 w-[72%] h-[1px] bg-th-gray-100 dark:bg-th-border-1" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface IChannelLastMessage {
  message: IChannelMessage;
  id: string;
}

export const ChannelLastMessage: React.FC<IChannelLastMessage> = ({ id }) => {
  const { userInfo } = useAuth();
  const { detail } = useChannel(id);

  const message = detail?.last_message;

  const sender =
    message?.sender?.id === userInfo?.user_id ? 'Bạn' : message?.sender?.fullname?.split(' ')?.at(-1);

  const getLastMsg = (message: IChannelMessage) => {
    if (message?.text) return message.text;
    const attachments = message?.attachments;

    if (attachments?.length > 0) {
      const lastAttachment = attachments?.at(-1);

      if (attachments.some((attachment) => !attachment.mime.includes('image')))
        return (
          <div className="flex items-center gap-1">
            <ChatLink className="scale-75" /> Tệp
          </div>
        );

      if (lastAttachment?.mime?.includes('image'))
        return (
          <div className="flex items-center gap-1">
            <ChatImg className="scale-75 -translate-y-[1px]" /> Ảnh
          </div>
        );

      if (lastAttachment?.mime?.includes('video')) {
        return (
          <div className="flex items-center gap-1">
            <ChatImg className="scale-75 -translate-y-[1px]" /> Video
          </div>
        );
      }
    }
  };

  const lastMessage = getLastMsg(message);

  return (
    <div className="mb-0 mr-8 text-sm flex items-center text-th-text">
      {sender}: <div className="ml-2 truncate">{lastMessage}</div>
    </div>
  );
};
