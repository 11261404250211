import { trueTypeOf } from '@/configs/Chat/utils';
import { Avatar } from '@/UI';
import React from 'react';
import { IGroupImage } from '@/configs/Chat/interfaces';
import classNames from 'classnames';

export const ChannelAvatar = ({
  src,
  name,
  isOnline = false,
  hasShadow = false,
}: {
  src: string | IGroupImage[];
  name: string;
  isOnline?: boolean;
  hasShadow?: boolean;
}) => {
  const type = trueTypeOf(src);

  const GAvatar = ({
    src,
    className,
    name,
  }: {
    src?: string | undefined;
    className: string;
    name: string;
  }) => (
    <Avatar
      src={src}
      className={classNames(className, {
        'bg-th-primary': !src,
        'avatar-shadow': hasShadow,
      })}
    >
      {name}
    </Avatar>
  );

  const OnlineStatus = () =>
    isOnline && (
      <div className="absolute bottom-[2px] right-[2px] rounded-full h-[10px] w-[10px] bg-th-primary-200 border-2 border-solid border-th-white" />
    );

  if (!src) {
    return (
      <>
        <GAvatar className="w-full h-full bg-th-primary" name={name?.[0]} />
        <OnlineStatus />
      </>
    );
  }

  if (type === 'string') {
    return (
      <>
        <GAvatar src={src as string} className="w-full h-full uppercase" name={name?.[0]} />
        <OnlineStatus />
      </>
    );
  }

  const groupImages = src as IGroupImage[];

  return (
    <>
      {groupImages.length > 1 && (
        <div className="relative h-full w-full">
          <GAvatar
            src={groupImages[0]?.avatar}
            className="w-3/4 h-3/4 absolute top-0 right-0 uppercase"
            name={groupImages[0]?.fullname}
          />
          <GAvatar
            src={groupImages[1]?.avatar}
            className="w-3/4 h-3/4 absolute bottom-0 left-0 uppercase"
            name={groupImages[1]?.fullname}
          />
        </div>
      )}

      {groupImages.length === 1 && (
        <GAvatar
          src={groupImages[0]?.avatar}
          className="w-full h-full uppercase"
          name={groupImages[0]?.fullname}
        />
      )}

      {groupImages.length === 0 && (
        <GAvatar className="w-full h-full uppercase bg-th-primary" name={name?.[0]} />
      )}

      <OnlineStatus />
    </>
  );
};

export default ChannelAvatar;
