import classNames from 'classnames';
import { ArrowRightIcon } from '@/components/icons';
import { useRouter } from 'next/router';

const PageView = ({ views }) => {
  const router = useRouter();

  return (
    <div className="flex items-center gap-1">
      {views.map((view, index) => (
        <div key={index} className="flex items-center gap-1">
          <span
            className={classNames('cursor-pointer border-0 hover:text-th-primary', {
              'text-th-primary': view.key === router.asPath,
            })}
            onClick={() => router.push(view.key)}
          >
            {view.label}
          </span>
          {index !== views.length - 1 && <ArrowRightIcon className="text-th-text-secondary" />}
        </div>
      ))}
    </div>
  );
};

export default PageView;
