import { Divider, Pagination } from '@/UI';
import PageView from './PageView';
import { memo } from 'react';

interface LayoutProps {
  children: React.ReactNode;
  filter?: React.ReactNode;
  groupBtns?: React.ReactNode;
  paginationProps?: Omit<React.ComponentProps<typeof Pagination>, 'className'>;
  views?: any;
  title?: string;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  filter = null,
  groupBtns = null,
  paginationProps = null,
  views = null,
  title = '',
}) => (
  <div className="flex flex-col text-th-text bg-th-background flex-1 h-full">
    <div className="flex-1 overflow-auto flex flex-col">
      <div className="flex items-center justify-between px-3 py-2 bg-th-background-1 min-h-[52px]">
        <h2 className="text-xl mb-0 font-semibold leading-6 capitalize">{title.toLowerCase()}</h2>
        {groupBtns}
      </div>

      <div className="flex items-center gap-2 p-3 w-full">
        {views ? <PageView views={views} /> : null}
        {views ? <Divider type="vertical" className="border-th-border h-full" /> : null}
        {filter}
      </div>

      {children}
    </div>
    {paginationProps && (
      <Pagination
        className="sticky bottom-0 bg-th-background border-0 border-t border-solid border-th-border py-2 px-3 z-10"
        {...paginationProps}
      />
    )}
  </div>
);

export default memo(Layout);
