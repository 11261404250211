import { Divider } from '@/UI';
import { DisabledEyesIcon, EyeIcon } from '@/components/icons';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { endpoints, viewPhoneShop } from '@/services/Customer.service';
import { useAuth } from '@/configs/Auth/client';
import { APIResponse } from '@/interfaces';
import { showMessage } from '@/components/messages/GMessage';

interface ISecretField {
  defaultText?: string;
  queryFn?: () => Promise<string>;
  queryKey?: any[];
}

const SecretField = ({ defaultText, queryFn, queryKey = [] }: ISecretField) => {
  const [show, setShow] = useState(false);
  const handleShow = async () => setShow((prev) => !prev);

  const { data } = useQuery({
    queryKey: [...queryKey, show],
    queryFn,
    enabled: show,
    staleTime: Infinity,
  });

  return (
    <p className="inline-flex items-center cursor-pointer hover:underline gap-1 mb-0" onClick={handleShow}>
      {show && data ? data : defaultText || '***'}

      <span className="text-primary flex items-center">
        {show && data ? <EyeIcon /> : <DisabledEyesIcon className="text-lg" />}
      </span>
    </p>
  );
};

const OtherInfo = ({ record }) => {
  const { shop_contacts, shop_category } = record || {};
  const { userInfo } = useAuth();
  return (
    <div className="flex flex-col gap-1 items-start ">
      {shop_category?.filter(Boolean)?.length
        ? `Ngành hàng: ${shop_category?.filter(Boolean)?.join(', ')}`
        : null}
      <Divider className="my-2" />
      {shop_contacts?.map((p, index) => (
        <span key={index}>
          Đầu mối làm việc: {p?.full_name} - {p?.issues} -{' '}
          <SecretField
            defaultText={p?.phone}
            queryKey={[endpoints.SHOP_PHONE, p?.id]}
            queryFn={async () => {
              const { data, error }: APIResponse = await viewPhoneShop(p?.id, userInfo?.username);
              if (error) return showMessage.error(error);
              return data;
            }}
          />
        </span>
      ))}
    </div>
  );
};

export default OtherInfo;
