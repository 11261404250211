import ApiClient from '@/configs/ApiClient';

const APP_URL = process.env.NEXT_PUBLIC_APP_URL;
const IAM_URL = process.env.NEXT_PUBLIC_GAM_IAM_URL;

const api = new ApiClient(`${APP_URL}/api/auth`).getInstance();
const iamApi = new ApiClient(`${IAM_URL}/api/v1`).getInstance();

export const endpoints = {
  login: '/login',
  loginWith3rdParty: 'login-with-3rd-party',
  callback: '/callback',
  session: '/session',
  logout: '/logout',
  refresh_token: '/refresh_token',
  sending_opt: '/users/login/otp/send',
  forgot_password: '/users/forgot-password',
  users: '/users',
  user_info: '/users/userinfo',
};

interface ILogin {
  otp?: string;
  identity?: string;
  password?: string;
  type: 'basic' | 'otp';
}

export const login = (params: ILogin) => {
  return api.post(endpoints.login, params);
};

export const getSession = () => {
  return api.get(endpoints.session);
};

export const getUserInfo = () => iamApi.get(endpoints.user_info);
