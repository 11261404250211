import { IChannelMessage } from '@/configs/Chat/interfaces';
import React from 'react';
import { MessageContent } from './MessageContent';
import cn from 'classnames';
import { chatInstance } from '@/configs/Chat';
import { IChannelMessageMode } from '@/configs/Chat/modules/InternalStorage';

interface IProps {
  message: IChannelMessage;
  type: 'text' | 'attachment';
}

export const MessageQuote: React.FC<IProps> = ({ message, type }) => {
  let content;
  const quoteMessage = message?.quote_message;
  const user = message?.is_current_user ? 'Bạn' : message?.sender?.fullname;

  const handleScrollToQuote = async () => {
    let msgElement = document.querySelector(
      `[data-message-key="${message?.quote_message?.id}"] .message-content`
    );

    if (msgElement) return handleScollToQuote(msgElement);

    await chatInstance.handleGetMessages(
      {
        channel_id: message?.channel_id,
        middle: message?.quote_message?.id,
        limit: 40,
      },
      [IChannelMessageMode.BEFORE, IChannelMessageMode.AFTER]
    );

    msgElement = document.querySelector(
      `[data-message-key="${message?.quote_message?.id}"] .message-content`
    );
    if (msgElement) return handleScollToQuote(msgElement);
  };

  const handleScollToQuote = (element: Element) => {
    element.scrollIntoView({ block: 'center' });

    element.classList.add('animate-tada', 'outline');
    setTimeout(() => {
      element.classList.remove('animate-tada', 'outline');
    }, 3000);
  };

  if (quoteMessage?.text && type === 'text')
    content = (
      <div
        className={cn('opacity-40 relative translate-y-3 -mt-2 flex', {
          'justify-end': message?.is_current_user,
          'justify-start': !message?.is_current_user,
        })}
      >
        <MessageContent message={quoteMessage} isQuote />
      </div>
    );

  if (quoteMessage?.attachments?.length > 0 && type === 'attachment')
    content = (
      <div
        className={cn('relative rounded-md flex flex-col cursor-pointer translate-y-4', {
          'items-end': message?.is_current_user,
          'items-start': !message?.is_current_user,
        })}
      >
        <span
          className={cn('absolute italic z-40 -top-4 text-th-gray-500 text-xs whitespace-nowrap', {
            'right-0': message?.is_current_user,
            'left-0': !message?.is_current_user,
          })}
        >
          {user} đã trích dẫn {quoteMessage?.attachments?.length} đính kèm
        </span>
        <div className="opacity-50">
          <MessageContent message={quoteMessage} />
        </div>
      </div>
    );

  return (
    <div className="relative h-full w-full">
      <div
        className={cn('absolute inset-0 -bottom-4 top-2 z-20 cursor-pointer')}
        onClick={handleScrollToQuote}
      />
      {content}
    </div>
  );
};
