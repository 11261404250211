/* eslint-disable no-unused-vars */
export enum WsListenerType {
  MESSAGES = 'messages',
  CHANNELS = 'channels',
  CHANNEL_MESSAGE_MODE = 'channel_message_mode',
  CONNECT = 'connect',
  CHANNEL = 'channel',

  INTERNAL_GETTING_GROUP_PATH = 'internal_getting_group_path',
  INTERNAL_GETTING_CHANNEL = 'internal_getting_channel',
  INTERNAL_GETTING_MESSAGE = 'internal_getting_message',
}

export const INTERVAL_CHECK_PING = 10000;
export const RECONNECT_LIMIT = 5;

// export const RECONNECT_TIMEOUT = [3000, 6000, 12000, 24000, 48000];
export const RECONNECT_TIMEOUT = Array.from({ length: RECONNECT_LIMIT }, (_, i) => 3000 * 2 ** i);

export const FORMAT_DATETIME = 'DD/MM/YYYY HH:mm';
export const MESSAGES_LIMIT = 40;

// 5 minutes
export const CACHE_MESSAGES_MS = 1000 * 60 * 5;

export const KEEP_CONNECTION_MESSAGE = {
  PING: '55',
  PONG: '55',
};
