export const PUBLIC_ROUTER = [
  '/login',
  '/signup',
  '/signup/infor',
  '/signup/pageview',
  '/api/auth/callback',
  '/callback',
];
export const PRIVATE_ROUTER = ['/'];

export const NOT_FOUND_ROUTER = '/errors/404';

export const ERROR_ROUTER = ['/errors/500', '/errors/404', '/callback'];
