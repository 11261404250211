import { Button, DatePicker, Form, Input, Select } from '@/UI';
import { Search } from '@/components/icons';
import lodash from '@/utils/lodash';
import { useRefetch } from '@/hooks/useRefetch';
import { endpoints } from '@/services/CustomerInsight.service';
import { filterInvalidField } from '@/utils/functions';
import ShopStatusSelect from '@/modules/customer/components/ShopStatusSelect';

const SOURCE_OPTIONS = [
  { label: 'GHTK app', value: 1 },
  { label: 'GAM app', value: 2 },
  { label: 'GAM website', value: 3 },
];

const APPROVALS = {
  active: [4],
  inactive: [1, 2, 3],
};

const Filter = ({ filters, setFilters }) => {
  const { refetch } = useRefetch(endpoints.DEPLOYMENT_PROCESS);

  const onFinish = (values) => {
    const [active_time_from, active_time_to] = values.date?.map((d) => d.format('DD/MM/YYYY')) || [];
    const approvalStatus = values.approval_status?.map((status) => APPROVALS[status]).flat();

    const newFilters = filterInvalidField({
      ...filters,
      ...values,
      approval_status: approvalStatus?.join(','),
      source: values.source?.join(','),
      active_time_from: active_time_from ? `${active_time_from} 00:00:00` : undefined,
      active_time_to: active_time_to ? `${active_time_to} 23:59:59` : undefined,
      date: undefined,
    });
    const isChangedFilter = !lodash.isEqual(newFilters, filters);

    if (filters.page !== 1 || isChangedFilter) return setFilters({ ...newFilters, page: 1 });

    refetch();
  };

  return (
    <Form className="flex items-center gap-3 flex-1" onFinish={onFinish}>
      <Form.Item noStyle name="shop_code">
        <Input
          prefix={<Search />}
          className="w-full"
          placeholder="Nhập mã shop, cách nhau bởi dấu ','"
          allowClear
        />
      </Form.Item>
      <Form.Item noStyle name="phone_number_email">
        <Input
          prefix={<Search />}
          className="w-full"
          placeholder="Nhập SĐT/email, cách nhau bởi dấu ','"
          allowClear
        />
      </Form.Item>
      <Form.Item noStyle name="date">
        <DatePicker.RangePicker className="w-full" placeholder={['Bắt đầu sử dụng từ', 'đến']} allowClear />
      </Form.Item>
      <Form.Item noStyle name="source">
        <Select
          className="w-full"
          placeholder="Nguồn đăng kí"
          allowClear
          options={SOURCE_OPTIONS}
          mode="multiple"
          maxTagCount="responsive"
        />
      </Form.Item>
      <Form.Item noStyle name="approval_status">
        <ShopStatusSelect />
      </Form.Item>
      <Button type="primary" htmlType="submit" className="w-fit">
        Tìm kiếm
      </Button>
    </Form>
  );
};

export default Filter;
