import ApiClient from '@/configs/ApiClient';
import { IInsightDetailParams, ILikeActionParmas } from '@/modules/customer-insight-deployment/interface';
import { GAMP_HEADER } from '@/constants';

const GAM_URL = `${process.env.NEXT_PUBLIC_GAMP_GATEWAY_API_URL}/solution`;

export const api = new ApiClient(GAM_URL, GAMP_HEADER).getInstance();

export const endpoints = {
  DEPLOYMENT_PROCESS: '/api/v1/cis/instance/deployment-process',
  INSIGHTS: '/api/v1/cis/instance/deployment-process/insights',
  SOLUTION_DETAIL: '/api/v1/cis/instance/deployment-process/solution-details',
  FEE_UNITS: '/api/v1.1/fee-units',
  SERVICE_COMMITMENT: '/api/v1.1/service-commitment',
  MONITOR_FACTORS: '/api/v1.1/monitor-factors',
  PREVIEW_CONTRACT: '/api/v1/cis/instance/contract/preview',
  STEPS: '/api/v1/cis/instance/deployment-process/steps',
  SORT_TEST: '/api/v1/cis/instance/deployment-process/steps/positions',

  NOTES: '/api/v1/cis/instance/notes',
  LOGS: '/api/v1/cis/instance/deployment-process/logs',

  CONTRACT: '/api/v1/cis/instance/deployment-process/contract',
  FEEDBACK: '/api/v1/cis/instance/deployment-process/feedback',
  TRANSFORMATION: '/api/v1/cis/instance/deployment-process/transformation',
  ACTIVATE_SOLUTION: '/api/v1/cis/instance/deployment-process/active',
  GET_INSIGHT_DETAIL: '/api/v1/cis/instance/deployment-process/insights/detail',
  INSIGHT_ACTION: '/api/v1/subjects/insights/action',
};

export const getDeploymentProcess = (params = {}) => api.get(endpoints.DEPLOYMENT_PROCESS, { params });

export const getInsights = (params) => api.get(endpoints.INSIGHTS, { params });

export const getSolutionsDetail = (params) => api.get(endpoints.SOLUTION_DETAIL, { params });

export const updateSolutionDetail = (data) => api.put(endpoints.SOLUTION_DETAIL, data);

export const getFeeUnits = () => api.get(endpoints.FEE_UNITS);

export const getServiceCommitment = () => api.get(endpoints.SERVICE_COMMITMENT);

export const getMonitorFactors = () => api.get(endpoints.MONITOR_FACTORS);

export const previewContract = (data) => api.post(endpoints.PREVIEW_CONTRACT, data);

export const actionSteps = (data) => api.put(endpoints.STEPS, data);

export const sortSteps = (data) => api.put(endpoints.SORT_TEST, data);

export const getNotesOptions = (params) => api.get(endpoints.NOTES, { params });

export const createNote = (params) => api.post(endpoints.NOTES, params);

export const getLogs = (params) => api.get(endpoints.LOGS, { params });

export const getContract = (params) => api.get(endpoints.CONTRACT, { params });

export const saveContract = (data) => api.put(endpoints.CONTRACT, data);

export const getFeedback = (params) => api.get(endpoints.FEEDBACK, { params });

export const saveFeedback = (data) => api.put(endpoints.FEEDBACK, data);

export const getTransformation = (params) => api.get(endpoints.TRANSFORMATION, { params });

export const saveTransformation = (data) => api.put(endpoints.TRANSFORMATION, data);

export const getActivateSolution = (params) => api.get(endpoints.ACTIVATE_SOLUTION, { params });

export const saveActivateSolution = (data) => api.put(endpoints.ACTIVATE_SOLUTION, data);

export const getInsightDetail = (params: IInsightDetailParams) =>
  api.get(endpoints.GET_INSIGHT_DETAIL, { params });

export const saveLikeAction = (params: ILikeActionParmas) => api.post(endpoints.INSIGHT_ACTION, params);
