import { EmoijiIcon } from '@/components/icons';
import EmojiPicker from 'emoji-picker-react';
import { useState } from 'react';

export const EmoijiPicker = ({ onAddEmoji }) => {
  const [openPicker, setOpenPicker] = useState(false);

  return (
    <div className="relative">
      {openPicker && (
        <EmojiPicker
          className="emoji-picker !absolute bottom-[35px] right-[15px]"
          skinTonesDisabled
          allowExpandReactions={false}
          previewConfig={{ showPreview: false }}
          onEmojiClick={(data) => {
            onAddEmoji(data.emoji);
            setOpenPicker(false);
          }}
        />
      )}
      <EmoijiIcon className="ml-2 mr-1 w-[30px] cursor-pointer" onClick={() => setOpenPicker(!openPicker)} />
    </div>
  );
};
