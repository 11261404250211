import { WidgetChat } from '@/modules/chat/components/widgets/components';
import { Fragment } from 'react';
import { useChat } from '@/modules/chat';
import ChannelAvatar from '@/modules/chat/components/ChannelAvatar';
import { Divider, Popover } from '@/UI';
import { ChannelLastMessage } from '@/modules/chat/components/ChannelItem';
import { EllipsisOutlined } from '@ant-design/icons';
import { CloseIcon } from '@/components/icons';
import { WIDGETS_MINIMIZED_MAX_SHOW } from '@/modules/chat/constants';

const Widgets = () => {
  const { widgets, setWidgets, addChannel, minimizeAll } = useChat();

  const widgetsShow = widgets.filter((item) => !item.is_minimized);
  const widgetsMinimized = widgets.filter((item) => item.is_minimized);
  const widgetsHidden = widgetsMinimized.slice(WIDGETS_MINIMIZED_MAX_SHOW);

  const channelContent = (channel) => (
    <div className="max-w-[300px]">
      <div className="truncate font-semibold text-th-text">
        {channel?.channel_name || `${channel?.channel_type.toLocaleUpperCase()} ${channel?.channel_id}`}
      </div>
      <ChannelLastMessage message={channel?.last_message} id={channel?.channel_id} />
    </div>
  );

  const actionContent = (
    <div className="max-w-[300px]">
      <div className="cursor-pointer" onClick={minimizeAll}>
        Thu gọn đoạn chat đang mở
      </div>
      <Divider className="my-2" />
      <div className="cursor-pointer" onClick={() => setWidgets([])}>
        Đóng tất cả đoạn chat
      </div>
    </div>
  );

  const widgetsHiddenContent = () => (
    <>
      {widgetsHidden?.map((channel) => (
        <div
          key={channel.channel_id}
          className="p-2 hover:bg-gray-200 rounded w-60 cursor-pointer group/widget-hidden relative"
          onClick={() => addChannel(channel)}
        >
          {channel?.channel_name}

          <CloseIcon
            className="w-4 text-black absolute top-3 right-2 hidden group-hover/widget-hidden:block"
            onClick={(e) => onClose(e, channel.channel_id)}
          />
        </div>
      ))}
    </>
  );

  const onClose = (e: React.MouseEvent, channelID: string) => {
    e.stopPropagation();
    setWidgets(widgets.filter((item) => item.channel_id !== channelID));
  };

  return (
    <div className="fixed bottom-0 right-5 flex items-end z-[99] gap-2">
      {widgetsShow?.map((channel) => (
        <Fragment key={channel?.channel_id}>
          <WidgetChat channelID={channel?.channel_id} />
        </Fragment>
      ))}
      <div className="flex flex-col items-center justify-end mb-5 gap-2 w-12 group/minimize-box">
        <div className="w-8 h-8 bg-white rounded-full  items-center justify-center avatar-shadow cursor-pointer hidden group-hover/minimize-box:flex">
          <Popover content={actionContent} placement="left">
            <EllipsisOutlined className="text-2xl" />
          </Popover>
        </div>

        {widgetsMinimized.slice(0, WIDGETS_MINIMIZED_MAX_SHOW)?.map((channel) => (
          <Fragment key={channel?.channel_id}>
            <Popover content={channelContent(channel)} placement="left">
              <div
                className="relative w-12 h-12 rounded-full cursor-pointer group/minimize-chat"
                onClick={() => addChannel(channel)}
              >
                <ChannelAvatar
                  src={channel?.avatar ?? channel?.group_images}
                  name={channel?.channel_name}
                  isOnline
                  hasShadow
                />

                <div
                  className="absolute top-0 right-0 w-4 h-4 items-center justify-center rounded-full bg-th-background-document hidden group-hover/minimize-chat:flex"
                  onClick={(e) => onClose(e, channel.channel_id)}
                >
                  <CloseIcon className="w-3 text-white" />
                </div>
              </div>
            </Popover>
          </Fragment>
        ))}

        {!!widgetsHidden.length && (
          <Popover content={widgetsHiddenContent} placement="leftBottom">
            <div className="w-12 h-12 relative rounded-full cursor-pointer group/minimize-chat">
              <ChannelAvatar
                src={widgetsHidden?.[0]?.avatar ?? widgetsHidden?.[0]?.group_images}
                name={widgetsHidden?.[0]?.channel_name}
              />
              <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black/60 text-th-white rounded-full">
                +{widgetsMinimized.length - WIDGETS_MINIMIZED_MAX_SHOW}
              </div>
            </div>
          </Popover>
        )}
      </div>
    </div>
  );
};

export default Widgets;
