import { IChannelMessage } from '@/configs/Chat/interfaces';
import cn from 'classnames';
import AttachmentPreview from '../attachments/AttachmentPreview';
import { TextContent } from './TextContent';

interface IMessageContentProps {
  message: IChannelMessage;
  isCurrentUser?: boolean;
  onClickImg?: (id: string) => void;
  isQuote?: boolean;
}

export const MessageContent: React.FC<IMessageContentProps> = ({
  message,
  isCurrentUser,
  onClickImg,
  isQuote,
}) => {
  if (message?.attachments?.length) {
    const messagesImgs = message.attachments.filter((attachment) => attachment?.mime?.includes('image'));
    const otherAttachments = message.attachments.filter((attachment) => !attachment?.mime?.includes('image'));

    return (
      <>
        <div
          className={cn('flex gap-1 flex-wrap mb-1', {
            'justify-end': isCurrentUser,
            'justify-start': !isCurrentUser,
          })}
        >
          {messagesImgs.map((attachment, index) => (
            <AttachmentPreview
              onClickImg={onClickImg}
              length={message.attachments.length}
              key={index}
              attachment={attachment}
            />
          ))}
        </div>
        <div className="flex flex-col gap-1">
          {otherAttachments.map((attachment, index) => (
            <AttachmentPreview key={index} attachment={attachment} length={message.attachments.length} />
          ))}
        </div>
      </>
    );
  }

  return (
    <div
      className={cn('p-2 rounded-lg w-fit relative z-10', {
        'bg-th-primary text-th-background': isCurrentUser,
        'bg-th-gray-80': !isCurrentUser,
        '!pb-4': isQuote,
      })}
      style={{ wordBreak: 'break-word' }}
    >
      <TextContent text={message?.text} />
    </div>
  );
};
