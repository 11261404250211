import { Input } from '@/UI';
import { useRef } from 'react';
import { EmoijiPicker } from './EmoijiPicker';

const ENTER_KEY = 13;

export const MessageInput = ({ onSendMessage, inputValue, setInputValue, handlePasteImg }) => {
  const textareaRef = useRef(null);
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const insertEmojiAtCursor = (emoji) => {
    const textarea = textareaRef.current?.resizableTextArea?.textArea;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    // Insert the text at the cursor position
    const before = textarea.value.substring(0, start);
    const after = textarea.value.substring(end);
    const newValue = before + emoji + after;
    textarea.value = newValue;
    setInputValue(newValue);

    // Set the cursor position to the end of the inserted text
    textarea.setSelectionRange(start + emoji.length, start + emoji.length);

    // Focus the textarea
    textarea.focus();
  };

  const handleAddEmoji = (emoji) => {
    insertEmojiAtCursor(emoji);
  };

  const handleKeyDown = async (event) => {
    if (event.keyCode === ENTER_KEY && !event.shiftKey) {
      event.preventDefault();
      onSendMessage();
    }
  };

  return (
    <div className="flex items-end">
      <Input.TextArea
        onPaste={handlePasteImg}
        ref={textareaRef}
        id="chat-input"
        className="w-full border-0 pl-4 bg-th-background-2 rounded-3xl shadow-none max-h-[100px] overflow-auto"
        placeholder="Nhập tin nhắn"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        style={{ resize: 'none' }}
        value={inputValue}
        rows={1}
      />
      <EmoijiPicker onAddEmoji={handleAddEmoji} />
    </div>
  );
};
