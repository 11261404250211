import React from 'react';
import { ChatContainer } from './ChatContainer';

interface IWidgetChat {
  channelID: string;
}

export const WidgetChat: React.FC<IWidgetChat> = ({ channelID }) => {
  return <ChatContainer channelID={channelID} />;
};
