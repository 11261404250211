import { useEffect, useState } from 'react';
import { WsListenerType } from '../constants';
import { IChannelData, IChannelID } from '../interfaces';
import { InternalStorage } from '../modules/InternalStorage';

interface IUseBaseChannel {
  channelID: IChannelID;
  subscribe: (params: { type: WsListenerType.CHANNEL; listener: () => void; channelID: IChannelID }) => any;
  _subcribeChannelsStatus: () => Promise<unknown>;
  internalStorage: InternalStorage;
}

export const useBaseChannel = ({
  _subcribeChannelsStatus,
  channelID,
  internalStorage,
  subscribe,
}: IUseBaseChannel) => {
  const [_, reRender] = useState({});
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    if (!channelID) return;

    const listener = () => reRender({});
    const unSubcribe = subscribe({ type: WsListenerType.CHANNEL, listener, channelID });

    const onSubChannel = async () => {
      setLoading(true);
      await _subcribeChannelsStatus();
      setLoading(false);
    };

    onSubChannel();

    return () => {
      unSubcribe();
    };
  }, [channelID]);

  const detail = internalStorage.data.channels?.find((channel) => channel?.channel_id === channelID);

  const onUpdateChannel = async (newChannel: IChannelData) => {
    internalStorage.setChannels((channels) => {
      const index = channels.findIndex((channel) => channel.channel_id === newChannel.channel_id);
      if (index === -1) return channels;
      channels[index] = { ...channels[index], ...newChannel };
      return [...channels];
    });
    reRender({});
  };

  return {
    detail,
    loading,
    onUpdateChannel,
  };
};
