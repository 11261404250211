import { AddWrapper, CloseIcon, Send } from '@/components/icons';
import { showMessage } from '@/components/messages/GMessage';
import { chatInstance } from '@/configs/Chat';
import { scrollToBottom } from '@/configs/Chat/utils';
import { useState } from 'react';
import { MAXIMUM_FILE_SIZE } from '../constants';
import { AttachmentUpload } from './attachments/AttachmentUpload';
import { Attachments } from './attachments/Attachments';
import { MessageInput } from './messages/MessageInput';
import { useChat } from '..';
import { TextContent } from './messages/TextContent';
import AttachmentPreview from './attachments/AttachmentPreview';

const ChannelFooter = () => {
  const { channelID, quoteMessage, setQuoteMessage } = useChat();
  const [attachments, setAttachments] = useState<File[]>([]);
  const [inputValue, setInputValue] = useState('');

  const handleAddAttachment = (newAttachments: File[]) => {
    setAttachments([...attachments, ...newAttachments]);
  };

  const handleUpload = async (event) => {
    const files = event.target.files;

    if (Array.from(files)?.some((file: any) => file?.size > MAXIMUM_FILE_SIZE)) {
      showMessage.error('Vui lòng chọn file có dung lượng nhỏ hơn 20MB');
      const fileConsist = Array.from(files).filter((file: any) => file?.size <= MAXIMUM_FILE_SIZE) as File[];
      handleAddAttachment(fileConsist);
      return;
    }

    handleAddAttachment(files);
  };

  const handleRemoveAttachment = (index) => {
    const newAttachments = attachments.filter((_, idx) => idx !== index);
    setAttachments(newAttachments);
  };

  const handleSendMsgs = async () => {
    const files = attachments;
    const message = inputValue.trim();
    const tempQuoteMessage = quoteMessage;

    if (!message && files.length === 0) return;
    setAttachments([]);
    setInputValue('');
    setQuoteMessage(null);

    await chatInstance.handleSendMessage({
      quoteMessage: tempQuoteMessage,
      message,
      files,
      channelID,
      onShowTempMessage: () => scrollToBottom(channelID),
    });
  };

  const handlePasteImg = (event) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const blob = items[i].getAsFile();
        handleAddAttachment([blob]);
      }
    }
  };

  return (
    <div className="chat-footer flex items-end justify-between gap-2 p-4 bg-white">
      <div className="footer-input flex-1 bg-th-background-2 rounded-3xl flex flex-col">
        {attachments.length > 0 && (
          <div className="preview-attachments flex gap-1 mx-3 py-3">
            <div className="preview-attachment-list flex gap-1 overflow-x-auto">
              {Array.from(attachments).map((attachment, index) => (
                <AttachmentUpload
                  key={index}
                  index={index}
                  attachment={attachment}
                  onRemoveAttachment={handleRemoveAttachment}
                />
              ))}
            </div>
            <AddMoreAttachment onUpload={handleUpload} />
          </div>
        )}

        {quoteMessage && (
          <div className="relative bg-th-background m-4 rounded-md">
            <div className="p-2 flex flex-col gap-2">
              {quoteMessage?.text && <TextContent text={quoteMessage?.text} />}

              {quoteMessage?.attachments?.length > 0 && (
                <div className="flex flex-col gap-2 overflow-x-auto">
                  <div className="italic text-sm">
                    Bạn đang trích dẫn {quoteMessage?.attachments?.length} đính kèm:
                  </div>

                  <div className="flex gap-1">
                    {quoteMessage.attachments.map((attachment, index) => (
                      <div key={index} className="max-w-[20%]">
                        <AttachmentPreview attachment={attachment} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="text-xs text-th-gray-400 italic">
                {quoteMessage?.sender?.fullname} - {quoteMessage?.created_at_text}
              </div>
            </div>

            <CloseIcon
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => {
                setQuoteMessage(null);
              }}
            />
          </div>
        )}
        <MessageInput
          handlePasteImg={handlePasteImg}
          onSendMessage={handleSendMsgs}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </div>
      {inputValue || attachments.length > 0 ? (
        <Send
          className="mb-[5px] cursor-pointer fill-th-primary w-[25px] h-[25px]"
          onClick={handleSendMsgs}
        />
      ) : (
        <Attachments onUpload={handleUpload} />
      )}
    </div>
  );
};

export default ChannelFooter;

const AddMoreAttachment = ({ onUpload }) => {
  return (
    <>
      <label
        htmlFor="upload"
        className="bg-th-gray-150 rounded-lg flex justify-center items-center cursor-pointer sticky right-0 w-[80px] h-[80px] p-2"
      >
        <AddWrapper />
      </label>
      <input id="upload" type="file" hidden accept="*" onChange={onUpload} multiple />
    </>
  );
};
