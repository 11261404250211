import { Avatar } from '@/UI';
import dayts from '@/utils/time';

const APPROVE_STATUS = {
  init: { label: 'Chưa kiểm duyệt', color: 'bg-th-orange-500' },
  processing: { label: 'Chưa kiểm duyệt', color: 'bg-th-orange-500' },
  rejected: { label: '', color: 'bg-th-primary' },
  approved: { label: 'Đang hoạt động', color: 'bg-th-primary' },
};

const GeneralInfo = ({ record }) => {
  const {
    shop_code,
    shop_name,
    phone_number,
    email,
    source,
    approval_status,
    active_time,
    active_by,
    avatar,
  } = record || {};

  const statusLabel = APPROVE_STATUS[approval_status] || '';
  return (
    <div className="flex flex-col gap-2 items-start">
      <div className="flex gap-2 items-center">
        <Avatar size={50} src={avatar || '/fallback.png'} />
        <div className="flex flex-col justify-between items-start min-h-[48px] flex-1">
          <p className="mb-0 flex items-center gap-2">
            <span className="font-medium leading-5">
              {shop_code} - {shop_name}
            </span>
            {statusLabel?.label && (
              <span className={`text-white rounded px-1 py-[2px] leading-4 text-sm ${statusLabel.color}`}>
                {statusLabel?.label}
              </span>
            )}
          </p>
          <span className="leading-5">
            <span className="underline">{phone_number}</span> / {email}
          </span>
        </div>
      </div>
      <span>Nguồn đăng ký: {source}</span>
      {shop_code?.startsWith('S') && <span>Mã ID GHTK: {shop_code}</span>}
      <span>
        Thời gian sử dụng GAM:{' '}
        {active_time ? dayts(active_time, 'DD/MM/YYYY HH:mm:ss').format('HH:mm DD-MM-YYYY') : ''}
      </span>
      {active_by && <span> CSKH duyệt tài khoản: {active_by}</span>}
    </div>
  );
};

export default GeneralInfo;
