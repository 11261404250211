import { Button } from '@/UI';
import { CloseIcon } from '@/components/icons';
import classNames from 'classnames';
import ChannelFooter from './components/ChannelFooter';
import ChannelHeader from './components/ChannelHeader';
import { MessagesList } from './components/messages/MessagesList';
import { useChat } from '.';

const ChannelView = () => {
  const { setCurrentChannelID } = useChat();

  return (
    <div className="channel-view flex">
      <div className={classNames('bg-th-white flex flex-col flex-1')}>
        <div className="h-14 border-0 border-b border-solid border-th-border grid items-center grid-cols-2 grid-rows-1 w-full py-4 px-2">
          <ChannelHeader />
          <Button
            type="text"
            shape="circle"
            className="justify-self-end"
            onClick={() => {
              setCurrentChannelID(null);
            }}
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="flex-1 overflow-auto p-4">
          <MessagesList />
        </div>
        <div className="border-0 border-t border-solid border-th-border">
          <ChannelFooter />
        </div>
      </div>
    </div>
  );
};

export default ChannelView;
